// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-width {
  width: 100%;
}

.select-style {
  background-color: red;
}

.label-div {
  padding-top: 16px;
  width: 225px;
  height: 1px;
}

mat-form-field {
  width: 245px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/submitter/submitter.dialog.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".select-width {\r\n  width: 100%;\r\n}\r\n\r\n.select-style {\r\n  background-color: red;\r\n}\r\n\r\n.label-div {\r\n  padding-top: 16px;\r\n  width: 225px;\r\n  height: 1px;\r\n}\r\n\r\nmat-form-field {\r\n  width: 245px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
