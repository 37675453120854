import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { ILocales } from '../shared/interfaces/locales';
import { ILocalInsurances } from '../shared/interfaces/localInsurances';
import { ILocalProviders } from '../shared/interfaces/localProviders';
import { ILocalProductors } from '../shared/interfaces/localProductors';
import { ILocalReferring } from '../shared/interfaces/localReferring';
import { ILocalFacilities } from '../shared/interfaces/localFacilities';
import { ILocalICD10 } from '../shared/interfaces/localICD10';
import { ILocalZipCodes } from '../shared/interfaces/localZipCodes';
import { IPcode } from '../shared/interfaces/pcodeObj';
import { IDedcode } from '../shared/interfaces/dedcodeObj';
import { IXcecode } from '../shared/interfaces/xcecodeObj';
import { IRecordsLastUsedParams } from '../shared/interfaces/recordsLastUsedParams';
import { IQ837 } from '../shared/interfaces/q837';
import { ILocalSubmitters } from '../shared/interfaces/submitter';

@Injectable({
  providedIn: 'root'
})
export class RecordService {
  sn: string;
  proc: string;
  postHeaders: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  getHeaders: any = { headers: new HttpHeaders({ 'Accept': 'application/json' }) };
  locales: ILocales[];
  localProviders: ILocalProviders[];
  localInsurances: ILocalInsurances[];
  localProductors: ILocalProductors[];
  localReferring: ILocalReferring[];
  localFacilities: ILocalFacilities[];
  localICD10: ILocalICD10[];
  localZipCodes: ILocalZipCodes[];
  localQ837: IQ837[];
  localSubmitters: ILocalSubmitters[];
  paymntModes: [{ value: string, name: string, pKey: string }];
  localPcodes: IPcode[] = [];
  localDedcodes: IDedcode[] = [];
  localXcecodes: IXcecode[] = [];

  constructor(private _http: HttpClient, private _help: HelperRtnsComponent) { }

  localesArr(dataSet): void {
    this.locales = dataSet;
  }

  localProvidersArr(dataSet): void {
    this.localProviders = dataSet;
  }

  localInsurancesArr(dataSet): void {
    this.localInsurances = dataSet;
  }

  localProductorsArr(dataSet): void {
    this.localProductors = dataSet;
  }

  localReferringArr(dataSet): void {
    this.localReferring = dataSet;
  }

  localFacilitiesArr(dataSet): void {
    this.localFacilities = dataSet;
  }

  localICD10Arr(dataSet): void {
    this.localICD10 = dataSet;
  }

  localZipCodesArr(dataSet): void {
    this.localZipCodes = dataSet;
  }

  localQ837Arr(dataSet): void {
    this.localQ837 = dataSet.filter(q => +q.pKey > 0);  // Filter a blank included just to get a result back
    this.localQ837.forEach(q => {
      if (q.stat) {
        q.status = JSON.parse(q.stat);
      } else {
        q.status = JSON.parse('{ "txOk": "", "txMsg": "", "chOk": "", "chMsg": "", "planOk": "", "planMsg": "" }');
      }
    });
  }

  localSubmittersArr(dataSet): void {
    this.localSubmitters = dataSet;
  }

  paymntModesArr(data): void {
    this.paymntModes = data;
  }

  private extractData(res: Response) {
    let returnedData = res;
    return returnedData || {};
  }

  lookUpIcd10CodeOrDescr(code: string, descr: string): Observable<any> {
    return this._http.get(this._help.urlDB + '/api/get-lookUpIcd10CodeOrDescr/' + encodeURIComponent(code) + '/' + encodeURIComponent(descr))
      .pipe(map(this.extractData));
  }

  getRecordCasesProcsPay(sn: string, id: string): Observable<any> {
    return this._http.get(this._help.urlDB + '/api/get-RecordCasesProcsPay/' + sn + '/' + id)
      .pipe(map(this.extractData));
  }

  postRenderJsRpt(bdy: any): Observable<any> {
    //let body = JSON.stringify({ "template": { "name": "/demographics/demographics-html" }, "data": { "lastNm": "Deniro", "firstNm": "Robert" }, "options": { "reports": { "save": true } } });
    let body = JSON.stringify(bdy);
    let unm = 'medbiler@gmail.com';
    let pw = 'XaxispjEQZ9r@w7';
    let b64 = 'Basic ' + btoa(unm + ':' + pw);
    let headers = { 'Content-Type': 'application/json', 'Accept': 'text/plain', 'Authorization': b64 };

    return this._http.post<any>('https://medbiller.jsreportonline.net/api/report', body, { headers, 'responseType': 'blob' as 'json' })
      .pipe(map(this.extractData));
  }

  postHl7Data2ApiServerXXX(bdy: any): Observable<any> {
    let body = JSON.stringify(bdy);
    let headers = { 'Content-Type': 'application/json' };

    return this._http.post(this._help.urlDB + 'Xapi/post-sendHl7File', body, { headers })
      .pipe(map(this.extractData));
  }

  postHl7Data2ApiServer(bdy: any): void { // This rtn doesn't work when coded as an Observable
    let body = JSON.stringify(bdy);
    let headers = { 'Content-Type': 'application/json' };

    this._http.post(this._help.urlDB + '/api/post-sendHl7File', body, { headers })
      .subscribe(response => response); // Response is null
  }

  postRecordsLastUsedParams(lastUsedParam: IRecordsLastUsedParams): Observable<any> {
    let body = JSON.stringify({ lastUsedParam });
    return this._http.post<any>(this._help.urlDB + '/api/post-recordsLastUsedParams', body, this.postHeaders);
  }

  getLastUsedParams(sn: string, userID: string): Observable<IRecordsLastUsedParams | any> {
    return this._http.get<IRecordsLastUsedParams>(this._help.urlDB + '/api/get-recordsLastUsedParams/' + sn + '/' + userID,
      this.getHeaders)
      .pipe(
        catchError(err => this.handlrHttpError(err))
      );
  }

  private handlrHttpError(error: HttpErrorResponse): Observable<any> {
    const errObj = {
      displayMsg: error.status.toString() + ' ' + error.statusText,
      msg: error.message
    }

    this.postApiErrorLog(this.sn, errObj, this.proc)
      .subscribe({
        next: (data: any) => console.log(data),
        error: (err: any) => console.error(err)
      });
    return throwError(() => errObj);
  }

  postApiErrorLog(SN: string, errObj: any, proc): Observable<any> {
    let errStr = JSON.stringify(errObj);
    let body = JSON.stringify({ SN, errStr, proc });

    return this._http.post<any>(this._help.urlDB + '/api/post-apiErrorLog', body, this.postHeaders);
  }

  errMsgSuggestion(engLang: boolean): string {
    return engLang ? "' Try refreshing (Ctrl-F5) the page or check the Internet service."
      : ' Trate refresh (Ctrl-F5) en la página o verifique el servicio de Internet.'
  }

}
