import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DataMessageService } from '../shared/data-message.service';
import * as io from 'socket.io-client';
import * as moment from 'moment';
import { WebsocketService } from '../shared/websocket.service';
import { HelperRtnsComponent } from '../shared/helper-rtns.component';
import { Subscription, of } from 'rxjs';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router'
import { ICommonElementStyles } from '../shared/interfaces/common-element-styles';
import { ICheck } from '../shared/interfaces/checks';
import { I835fil } from '../shared/interfaces/835fil';
import { I835ck } from '../shared/interfaces/835ck';
import { I835cas } from '../shared/interfaces/835cas';
import { I835svc } from '../shared/interfaces/835svc';
import { I835adj } from '../shared/interfaces/835adj';
import { ICkClaims } from '../shared/interfaces/ckClaims';
import { AppToastsService } from '../shared/app-toasts/app-toasts.service';
import { JsReportOnlineService } from '../shared/js-report-online.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CustomAdapter } from '../shared/datepicker/custom-adapter';
import { CustomDateParserFormatterYYYY } from '../shared/datepicker/custom-date-parser-formatter-yyyy';
import { CkRegister835DetailService } from './ck-register835-detail/ck-register835-detail.service';
import { CustomDateParserFormatter } from '../shared/datepicker/custom-date-parser-formatter';

interface IProc {
  detID: string,
  detCasID: string,
  detPS: string,
  fromDt: string,
  toDt: string,
  detPcode: string,
  mods: string,
  detQty: string,
  diags: string,
  detPos: string,
  usual: string,
  xpect: string,
  xpectOriginal: string,
  detPayed: string,
  newPayID: string,
  icn: string,
  pvdo: string,
  detDesc: string,
  detAMin: string,
  payment: string,
  payCkSeqNo: string,
  casSeqNo: string,
  ckPos: string,
  casPos: string,
  svcPos: string,
  payErr: boolean
}

interface ICarcNotes {
  code: string,
  descr: string
};

interface IProvNpi {
  ckgNpi: string,
  ProvLNm: string
};

interface IInsPayer {
  InID: string,
  insPayID: string,
  InLNm: string,
  reclTyp: string
}

@Component({
  selector: 'app-ck-register',
  templateUrl: './ck-register.component.html',
  styleUrls: ['./ck-register.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterYYYY }]
})
export class CkRegisterComponent implements OnInit, OnDestroy {
  // @ViewChild('ckRegClaimsPaymentDetailModal', { read: TemplateRef, static: true }) ckRegClaimsPaymentDetailModal: TemplateRef<any>;
  @ViewChild('reclaimsModalTmpl', { read: TemplateRef, static: true }) reclaimsModalTmpl: TemplateRef<any>;
  @ViewChild('successToast', { read: TemplateRef, static: true }) successToast: TemplateRef<any>;
  @ViewChild('reclaimsExitWoSaveModal', { read: TemplateRef, static: true }) reclaimsExitWoSaveModal: TemplateRef<any>;

  sn: string;
  sio: boolean = false;   // true when len(sn) = 4 using socket io in hybrid mode & false when using all web api access.
  showPopr1: boolean = false;
  showPopr2: boolean = false;
  showPopr3: boolean = false;
  showCkRxDtTxtBx: boolean = false;
  showChildPayments: boolean = false;
  adjustPromptModal: boolean;
  adjClm: any;  // Holds current claim for adjust, pay undo, etc. operations
  shown: boolean = false;

  stylesCfg: ICommonElementStyles;

  srchFromDt: string;
  srchFromDt2: string;  // Holds previous value
  srchToDt: string;
  srchToDt2: string;  // Holds previous value
  srchCkNo: string;
  srchDtType: string = '0'; // 0 = ck dt, 1 = received dt
  srchNpi: string = '';
  srchNpiTtip: string = '';
  srchPayerID: string = '';
  srchPayerIDttip: string = '';
  topCases: number = 600;
  deadCenter: boolean = false;
  currentPageCks: number = 1;
  currentPageClms: number = 1;
  currentPageCas: number = 1;

  checks: ICheck[];
  checkCopy: ICheck;  // Buffer copy of check being manipulated
  checkPrint: boolean = false;

  totals: any = {
    total: 0,
    credited: 0,
    balance: 0,
    count: 0
  }

  selectedCk: ICheck;

  filID: string;
  filNm: string;
  filDt: string;
  filAmnt: string;
  filPayerNm: string;
  filPayeeNm: string;

  ckgID: string;
  chkgDetID: string;
  chkgNo: string;
  chkgDt: string;
  chkgRxDt: string;
  chkgTotal: string;
  chkgBalance: string;
  chkgCredited: string;
  chkgTotalInp: string; // for input manipulation
  chkgTaxDed: string;
  chkgTaxDedInp: string;  // for input manipulation
  chkgMedDed: string;
  chkgMedDedInp: string;  // for input manipulation
  chkgOtherDed: string;
  chkgOtherDedInp: string;  // for input manipulation
  chkgNPI: string;
  chkgPayerId: string;
  chkgPayerId835: string;
  addCkErr: string;
  addCkSuccess: string;
  ckSelectedId: number = -1;
  clmSelectedIndex: number = -1;
  provsNPI: IProvNpi[];
  insPayerID: IInsPayer[];
  unCredPayCnt: string = '0';
  prevCredited: string = '0';

  reclFormTypSeled: string;
  reclCasIdSeled: string;
  reclPsSeled: string;
  reclInsID: string;
  reclId2delete: string;
  reclIdxSeld: number = -1; // Index in reclaims[] being edited
  reclRwIdxSeld: number = -1; // Index of row in reclaimsDet[] with focus
  reclCkBxId: string;  // html id of R checkbox checked
  reclCkBxCked: boolean; // True = checked as state of Reclaim checkbox with reclCkBxId when closing reclaim-component
  casReclTyp: string; // Initial default reclaim type for a particular case
  memorizeReclTyp: boolean; // Parameter used to memorize default reclaim type in [InsuranceCo] table 
  icnsPrevIdx: number = -1;

  clrReclData: boolean = false; // Clears reclaim data to defaults upon delete reclaim

  enabSaveRecl: boolean = false;

  saveToggle: boolean;

  deleteToggle: boolean;

  printToggle: boolean;

  modalRef: NgbModalRef;
  reclaimsModalRef: NgbModalRef;
  reclID: string;

  ckClaims: ICkClaims[];  // Manual payments candidates

  casIdLastShown: string;
  casIndxFound: string[] = [];  // Holds casID of cases found in a search
  claimSrchActive: boolean = false;
  reclaimedSrchActive: boolean = false;
  claimsAdjustedSrchActive: boolean = false;
  claimsPendingSrchActive: boolean = false;
  adjustRemove: boolean = false;
  undoPayment: boolean = false;
  adjCnt: string = '0';
  adjTotal: string = '0';
  reclCnt: string = '0';
  reclTotal: string = '0';
  pendCnt: string = '0';
  corrCnt: string = '0';
  showZeroBal: boolean = false;
  patNmModalHd: string;
  casNoModalHd: string;

  proc: IProc;
  procs: IProc[];
  savedPaymentsMax: number = 0;
  savedPaymentsCnt: number = 0;

  reclLineReasons: [{
    reclLineReason: string
  }];

  _835fil: I835fil[];
  _835ck: I835ck[];
  _835cas: I835cas[];
  _835svc: I835svc[];
  _835adj: I835adj[];
  _835arrLoadCnt: number = 0;
  unMatchedClaimCnt: number = 0;
  unMatchedPayCnt: number = 0;

  carcNotes: ICarcNotes[] = [];

  undoPaysArr: any[] = [];

  engLang: boolean;
  socket: any;
  sioSubscr: Subscription;
  userID: string;
  siteNm: string;
  siteAd1: string;
  siteAd2: string;
  siteCt: string;
  siteSt: string;
  siteZp: string;
  siteTl1: string;
  siteXt1: string;
  siteTl2: string;
  siteXt2: string;
  waiting4Response: boolean = false;  // for visual feedback of http calls
  spinner: boolean = true; // Spinner feedback for Reclaims Component
  modalStack: string[] = [];
  pID: string;  // For recordCaseModal
  cID: string;  // For recordCaseModal
  // showNpiDD: boolean[] = [];
  // showPayerIdDD: boolean[] = [];
  activeSqlProcs: [{
    sp: string,
    token: string
  }] = [{ sp: undefined, token: undefined }];
  finished: boolean = false;  // Indicates socketIO call to data base finished

  sortAsc: boolean = true;  //  Ascending, input to CkRegister835Detail component
  prevSrtClass: string; // Last class used to sort, input to input to CkRegister835Detail component

  claimsPropsReduced: { // All claims[] with properties reduced enough to search for name, contract or cas no
    'casID': string,
    'patLstNm': string,
    'patNm': string,
    'casCont': string,
    'casNo': string,
    'adjusted': string,
    'reclaimed': string,
    'casPsDesc': string,
    'indx': string
  }[] = [];

  base64prefix: string = 'data:image/png;base64, ';
  changeIcnObj: { nxtIcnIdx: number, nxtReclID: string, prvIcnIdx: number };

  constructor(private _dataMessageService: DataMessageService,
    private _websocketService: WebsocketService,
    private _helpRtns: HelperRtnsComponent,
    private _modalService: NgbModal,
    private _vref: ViewContainerRef,
    private _activatedRoute: ActivatedRoute,
    private _appToastsService: AppToastsService,
    private _jsRptService: JsReportOnlineService,
    private _ckRegister835DetailService: CkRegister835DetailService,
    private _clipboard: Clipboard) {
    this.socket = io(this._helpRtns.urlDB);
  }

  ngOnInit(): void {
    this._dataMessageService.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dataMessageService.currentUserIdStr.subscribe(userIdStr => this.userIdChange(userIdStr)); // Subscription looking for changes in userIdStr
    this._dataMessageService.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
    this._dataMessageService.currentSiteStr.subscribe(siteStr => this.siteChange(siteStr)); // Subscription looking for changes in siteName
    this._dataMessageService.currentSiteAd1Str.subscribe(siteAd1Str => this.siteAd1Change(siteAd1Str)); // Subscription looking for changes in siteAd1
    this._dataMessageService.currentSiteAd2Str.subscribe(siteAd2Str => this.siteAd2Change(siteAd2Str)); // Subscription looking for changes in siteAd2
    this._dataMessageService.currentSiteCtStr.subscribe(siteCtStr => this.siteCtChange(siteCtStr)); // Subscription looking for changes in siteCt
    this._dataMessageService.currentSiteStStr.subscribe(siteStStr => this.siteStChange(siteStStr)); // Subscription looking for changes in siteSt
    this._dataMessageService.currentSiteZpStr.subscribe(siteZpStr => this.siteZpChange(siteZpStr)); // Subscription looking for changes in siteZp
    this._dataMessageService.currentSiteTl1Str.subscribe(siteTl1Str => this.siteTl1Change(siteTl1Str)); // Subscription looking for changes in siteTl1
    this._dataMessageService.currentSiteXt1Str.subscribe(siteXt1Str => this.siteXt1Change(siteXt1Str)); // Subscription looking for changes in siteXt1
    this._dataMessageService.currentSiteTl2Str.subscribe(siteTl2Str => this.siteTl2Change(siteTl2Str)); // Subscription looking for changes in siteTl2
    this._dataMessageService.currentSiteXt2Str.subscribe(siteXt2Str => this.siteXt2Change(siteXt2Str)); // Subscription looking for changes in siteXt2

    this.stylesCfg = JSON.parse(this._activatedRoute.snapshot.params.stylesCfgJson);

    if (this.sio) {
      this.sioSubscr = this._websocketService.getMessages().subscribe((dataSet) => {
        if (dataSet?.length > 0) {

          console.log('%c' + 'dataSet in CkRegisterComponent.ngOnInit()', 'color: green; background: yellow; font-size: 12px');
          console.log('dataSet', dataSet);

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_UpdCheck') {
            if (dataSet.length == 1 && +dataSet[0].cksUpdated > 0) {
              this.waiting4Response = false;
              this.showSuccessToast();
              return;
            }
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_GetCkRegister') {
            if (dataSet[0].count == '0') {
              this._appToastsService.updateDeadCenter(true); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
              this._appToastsService.show(this.engLang ? dataSet[0].msgEng : dataSet[0].msgSpa, { header: (this.engLang ? dataSet[0].headEng : dataSet[0].headSpa), autohide: true, warning: true });
              this.waiting4Response = false;
              this.srchFromDt2 = undefined;
              this.srchToDt2 = undefined;
            } else {
              this.checks = dataSet;
              this.ddnListSelectionClkdForHeadFilter(undefined);  // This calls this.calcTotals();
              this.showSuccessToast();
              this.currentPageCks = 1;
              this.srchFromDt2 = this.srchFromDt;
              this.srchToDt2 = this.srchToDt;
            }
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_DeleteCheck') {
            if (dataSet[0].checksRowsDeleted > 0) {
              let i = this.checks.findIndex(ele => ele.ckgID == this.ckgID);
              if (i >= 0) {
                this.checks.splice(i, 1); // Deletes the check row
                this.checks = [...this.checks]; // reassign checks to update DOM
                this.calcTotals();
                this.showSuccessToast();
              }
            }
          }

          if (dataSet[0]?.sqlProcNm?.startsWith('spMB_Sio_GetCkRegisterLists')) {
            if (dataSet[0].sqlProcNm == 'spMB_Sio_GetCkRegisterLists_prv') {
              this.provsNPI = dataSet;
            }

            if (dataSet[0].sqlProcNm == 'spMB_Sio_GetCkRegisterLists_ins') {
              this.insPayerID = dataSet;
            }

            if (dataSet[0].sqlProcNm == 'spMB_Sio_GetCkRegisterLists_recLinRea') {
              this.reclLineReasons = dataSet;
              this.waiting4Response = false;
            }

            if (dataSet[0].sqlProcNm == 'spMB_Sio_GetCkRegisterLists_mru') { // [spMB_Sio_GetCkRegisterLists]
              let dt = dataSet[0].mruDtFrom;
              if ((moment(dt, 'MM/DD/YY').isValid())) {
                this.srchFromDt = dataSet[0].mruDtFrom;
              } else {
                this.srchFromDt = undefined;
              }
              dt = dataSet[0].mruDtTo;
              if ((moment(dt, 'MM/DD/YY').isValid())) {
                this.srchToDt = dataSet[0].mruDtTo;
              } else {
                this.srchToDt = undefined;
              }
              this.srchCkNo = dataSet[0].mruCkNo == 'undefined' ? undefined : dataSet[0].mruCkNo;
              this.srchDtType = dataSet[0].mruDtType == 'undefined' ? 0 : dataSet[0].mruDtType;
              this.srchNpi = dataSet[0].mruNpi == 'undefined' ? undefined : dataSet[0].mruNpi;
              this.srchPayerID = dataSet[0].mruPayerID == 'undefined' ? undefined : dataSet[0].mruPayerID;
              if ((this.srchCkNo || this.srchNpi || this.srchPayerID || this.srchFromDt || this.srchToDt) && this.srchDtType) {
                this.getCks();
              }
            }
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_AddCheck') {
            this.addCkSuccess = 'Ck: ' + this.chkgNo + ' ' + (this.engLang ? 'Saved!' : '¡Grabado!')
            if (+dataSet[0].cksExisting > 1) {
              this.addCkSuccess += '\n' + dataSet[0].cksExisting + ' ' + (this.engLang ? 'checks with same no. and date already exist in the register'
                : 'cheques con el mismo no. y fecha ya existen en el registro')
            }
            this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
            this._appToastsService.show(this.addCkSuccess, { header: this.engLang ? 'Check Saved' : 'Cheque Grabado', autohide: true, success: true });

            let newCk = {
              'ckgID': dataSet[0].ckgID,
              'ckDt': moment(this.chkgDt, 'YYYY-MM-DD').format('MM/DD/YY'),
              'ckgNo': this.chkgNo,
              'ckTotal': this.chkgTotalInp,
              'ckCredited': '0',
              'ckBalance': this.chkgTotalInp,
              'ckNPI': this.chkgNPI,
              'ckPayerID': this.chkgPayerId,
              'ckPayerID835': this.chkgPayerId835,
              'ckRxDt': (this.chkgRxDt ? moment(this.chkgRxDt, 'YYYY-MM-DD').format('MM/DD/YY') : undefined),
              'ckFilNm': '',
              'ckFilDt': '',
              'ckChildren': '0',  // No payments allocated to a new ck
              'hide': false,
              'ckPos': ''
            };
            if (this.checks) {
              this.checks.push(newCk);
            } else {
              this.checks = [newCk];
            }
            this.clearCkRegAddInputs();
            this.calcTotals();
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_ClaimCandidates4CkRegPayments') {
            this.procs = undefined; // Close any payment entry view
            if (dataSet[0].hasOwnProperty('rowCnt')) {
              if (dataSet[0].rowCnt == '0') { // Query produced no results
                this.ckClaims = [];
                this.topCases = this.ckClaims.length;
                this.claimsPropsReduced = [];
                this.pendCnt = '0';

                // this.reduceClaimsArrProps4search(dataSet);
                this.showSuccessToast();
              }
            } else {
              this.ckClaims = dataSet;
              this.casIdLastShown = dataSet[0].casID;
              this.topCases = this.ckClaims.length;

              let adjT: number = 0;
              let adjC: number = 0;
              let reclT: number = 0;
              let reclC: number = 0;
              let indx: number = 0; // Unique int for each claim to hide/unhide
              this.casIndxFound = [];

              this.ckClaims.forEach(e => {
                e.indx = indx.toString();
                this.casIndxFound.push(e.indx); // Initially contais all visible claims

                if (e?.adjusted == '1') {
                  adjC++;
                  adjT += +e.adjAmnt;
                }

                if (e?.reclaimed == '1') {
                  reclC++;
                  reclT += +e.reclAmnt;
                }

                indx += 1;
              });

              this.adjTotal = adjT.toFixed(2);
              this.adjCnt = adjC.toString();
              this.reclTotal = reclT.toFixed(2);
              this.reclCnt = reclC.toString();
              this.pendCnt = '0';

              this.reduceClaimsArrProps4search(dataSet);
              this.showSuccessToast();
            }
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_ClaimCandidates4CkRegPaymentsDetail') { // Display proc code detail for the clicked case
            if (this.procs?.length > 0) {  // Save any paymemt just entered before switching or closing claims
              this.savedPaymentsCnt = 0;
              this.savePayments();
            }

            if (this.casIdLastShown && this.casIdLastShown == dataSet[0].detCasID) {  // Clicked on the same claim entering payments
              this.procs = undefined; // Close payment entry view
              this.casIdLastShown = undefined;
              this.waiting4Response = false;
              return;
            }

            this.procs = dataSet; // Ready to open view for a new case to enter payments

            setTimeout(() => { // Allow time for the rendering to occur
              for (let p = 0; p < this.procs.length; p++) {
                let id = 'pay' + p;
                if (document.getElementById(id)) {
                  document.getElementById(id).focus();
                  console.log('focused');
                  break;
                }
              }
            }, 500);

            this.waiting4Response = false;
          }

          if (dataSet[0]?.sqlProcNm === 'spMB_Sio_SaveCkPayment') {
            if (+dataSet[0].payID > 0) {  // Payment saved or last payment saved
              this.savedPaymentsCnt++;

              for (let i = 0; i < this.checks.length; i++) {  // Update checks totals
                if (this.checks[i].ckgID == dataSet[0].ckgID) {
                  this.chkgBalance = (parseFloat(this.checks[i].ckBalance) - parseFloat(dataSet[0].payAmnt)).toFixed(2);
                  this.checks[i].ckBalance = this.chkgBalance;
                  this.chkgCredited = (parseFloat(this.checks[i].ckCredited) + parseFloat(dataSet[0].payAmnt)).toFixed(2);
                  this.checks[i].ckCredited = this.chkgCredited;
                  this.checks[i].ckChildren = (+this.checks[i].ckChildren + 1).toString();  // Payments allocated to this ck
                  break;
                }
              }

              if (this.ckClaims) {  // Manual payments
                for (let c = 0; c < this.ckClaims.length; c++) {  // Update claims totals - only manual payments
                  if (this.ckClaims[c].casID == dataSet[0].payCasID && this.ckClaims[c].ps == dataSet[0].ps) {
                    this.ckClaims[c].totPayment = dataSet[0].newClmPay;
                    this.ckClaims[c].balance = dataSet[0].newClmBal;
                    this.ckClaims[c].hasPayments = '1';
                    break;
                  }
                }

                this.undoPaysArr.push({
                  'payID': dataSet[0].payID,
                  'payCasID': dataSet[0].payCasID,
                  'ps': dataSet[0].ps,
                  'adjDetID': '0',
                  'payAmnt': dataSet[0].payAmnt,
                  'payDt': dataSet[0].payDt,
                  'payCkNo': dataSet[0].payCkNo,
                  'ckgID': dataSet[0].ckgID
                });

                this.waiting4Response = false;
              } else {  // 835 payment
                let i: number = this._835svc.findIndex(svc => svc.svcPos == dataSet[0].svcPos);
                this._835svc[i].paymentSavedLocation = dataSet[0].paymentSavedLocation; // Set payment location. 
                i = this._835cas.findIndex(cas => cas.casID == dataSet[0].payCasID && cas.casPS == dataSet[0].ps);
                this._835cas[i].casBal = dataSet[0].newClmBal;  // Update case balance
              }
            } else {
              if (!this.ckClaims) { // 835 payments attempted more than once
                let i: number = this._835svc.findIndex(svc => svc.svcPos == dataSet[0].svcPos);
                if (i >= 0) {
                  this._835svc[i].paymentSavedLocation = dataSet[0].paymentSavedLocation; // Set payment location. 
                }

                this.unCreditedPaymentsCnt();  // Sets this.unCredPayCnt
                // console.log('this.savedPaymentsMax', this.savedPaymentsMax);
                // console.log('this.prevCredited', this.prevCredited);
                // console.log('this.unMatchedPayCnt', this.unMatchedPayCnt);
                // console.log('this.unCredPayCnt', this.unCredPayCnt);
                // console.log('this.savedPaymentsCnt', this.savedPaymentsCnt);
                // console.log('SUM', this.savedPaymentsMax - +this.prevCredited - this.unMatchedPayCnt - +this.unCredPayCnt);
                if (this.savedPaymentsMax - +this.prevCredited - this.unMatchedPayCnt - +this.unCredPayCnt === this.savedPaymentsCnt) {
                  this.waiting4Response = false;
                  this.finished = true;
                }
                this.setPendCnt();  // Only for 835 payments
              }
            }


          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_UndoCkPayment') {
            this.undoPayment = false;
            if (dataSet[0].payDeletedRows == '1') { // Success undoing payment
              for (let i = 0; i < this.checks.length; i++) {  // Update checks totals
                if (this.checks[i].ckgID == dataSet[0].ckgID) {
                  this.chkgBalance = (parseFloat(this.checks[i].ckBalance) + parseFloat(dataSet[0].payAmnt)).toFixed(2);
                  this.checks[i].ckBalance = this.chkgBalance;
                  this.chkgCredited = (parseFloat(this.checks[i].ckCredited) - parseFloat(dataSet[0].payAmnt)).toFixed(2);
                  this.checks[i].ckCredited = this.chkgCredited;
                  let n: number = +this.checks[i].ckChildren - 1
                  if (n < 0) {
                    n = 0;
                  }
                  this.checks[i].ckChildren = n.toString();
                  break;
                }
              }

              for (let c = 0; c < this.ckClaims.length; c++) {  // Update claims totals - only manual payments
                if (this.ckClaims[c].casID == dataSet[0].payCasID && this.ckClaims[c].ps == dataSet[0].ps) {
                  this.ckClaims[c].totPayment = dataSet[0].newClmPay;
                  this.ckClaims[c].balance = dataSet[0].newClmBal;

                  if (dataSet[0].adjDeletedRows == '1') {
                    this.ckClaims[c].adjusted = '0';
                    this.ckClaims[c].adjDetID = '0';
                    this.adjTotal = (parseFloat(this.adjTotal) - parseFloat(this.ckClaims[c].adjAmnt)).toString();
                    this.ckClaims[c].adjAmnt = '0';
                    this.adjCnt = (parseInt(this.adjCnt) - 1).toString();
                  }

                  for (let i = this.undoPaysArr.length - 1; i >= 0; i--) {
                    if (this.undoPaysArr[i].payCasID == this.ckClaims[c].casID && this.undoPaysArr[i].ps == this.ckClaims[c].ps) {
                      this.undoPaysArr.splice(i, 1);
                      break;
                    }
                  }
                  break;
                }
              }
            } else {
              this._appToastsService.updateDeadCenter(false);
              this._appToastsService.show(this.engLang ? 'Claim balance might have changed.' :
                'Balance del caso pudo haber cambiado.', { header: (this.engLang ? 'Failed to undo.' : 'No se pudo.'), autohide: false, error: true });
            }
            this.waiting4Response = false;
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_CaseAdjust_man') {
            for (let c = 0; c < this.ckClaims.length; c++) {  // Update adjusted claim total & save pointer adjDetID for undue function
              if (this.ckClaims[c].casID == dataSet[0].adjustedCasID && this.ckClaims[c].ps == dataSet[0].ps) {
                this.ckClaims[c].balance = (parseFloat(this.ckClaims[c].balance) + parseFloat(dataSet[0].adjAmnt)).toFixed(2);
                this.ckClaims[c].adjDetID = dataSet[0].adjDetID;
                this.ckClaims[c].adjusted = '1';
                this.ckClaims[c].adjAmnt = dataSet[0].adjAmnt;
                this.adjCnt = (parseInt(this.adjCnt) + 1).toString();
                this.adjTotal = (parseFloat(this.adjTotal) + parseFloat(this.ckClaims[c].adjAmnt)).toFixed(2);
                this.calcTotals();
                break;
              }
            }
            this.waiting4Response = false;
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_CaseAdjust_835') {
            this.waiting4Response = false;
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_CaseAdjustRemove_man') {
            for (let c = 0; c < this.ckClaims.length; c++) {  // Restore claim total after removing adjustment 
              if (this.ckClaims[c].casID == dataSet[0].adjustedCasID && this.ckClaims[c].ps == dataSet[0].ps) {
                this.ckClaims[c].balance = parseFloat(dataSet[0].balance).toFixed(2);
                this.ckClaims[c].adjusted = '0';
                this.ckClaims[c].adjDetID = '0';
                this.adjTotal = (parseFloat(this.adjTotal) - parseFloat(this.ckClaims[c].adjAmnt)).toString();
                this.ckClaims[c].adjAmnt = '0';
                this.adjCnt = (parseInt(this.adjCnt) - 1).toString();
                break;
              }
              this.adjustRemove = false;
            }
            this.waiting4Response = false;
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_InsUpdReclaim') {
            // console.log('**ReclaimsSaved**');

            let reclT: number = 0;
            let reclC: number = 0;

            if (this.ckClaims?.length) {
              this.ckClaims[this.clmSelectedIndex].reclaimed = '1';
              reclT += +this.ckClaims[this.clmSelectedIndex].balance;
              reclC++;
            } else {
              this._835cas.forEach(cas => {
                if (cas.casID == dataSet[0].reclCasID && cas.casPS == dataSet[0].reclPS) {
                  cas.casRecled = '1';
                  reclC++;
                  reclT += +cas.casBal;
                }
              })
            }
            this.reclCnt = (+this.reclCnt + reclC).toString();
            this.reclTotal = (+this.reclTotal + reclT).toFixed(2);

            if (dataSet[0].end == 1) {
              this.waiting4Response = false;
              this.showSuccessToast();
            }
          }

          if (dataSet[0]?.sqlProcNm == 'spMB_Sio_DeleteReclaim') {
            if (dataSet[0].deletedReclaimsCnt > 0) {
              this.reclId2delete = undefined;
              this.showSuccessToast();

              if (this.ckClaims?.length) {  // Manual payments
                const i = this.ckClaims.findIndex((cas) => cas.casID === dataSet[0].reclCasID && cas.ps === dataSet[0].reclPS);
                if (i > 0) {
                  this.ckClaims[i].reclaimed = '0';
                  this.ckClaims[i].reclAmnt = '0';
                  this.reclTotal = (+this.reclTotal - +this.ckClaims[i].balance).toFixed(2);
                }
              } else {
                if (this._835cas?.length) { // 835 payments
                  const i = this._835cas.findIndex((cas) => cas.casID === dataSet[0].reclCasID && cas.casPS === dataSet[0].reclPS);
                  if (i > 0) {
                    this._835cas[i].casRecled = '0';
                    this.reclTotal = (+this.reclTotal - +this._835cas[i].casBal).toFixed(2);
                  }
                }
              }

              this.reclCnt = (+this.reclCnt - 1).toString();
              this.clrReclData = true;  // Clr reclaim data upon delete
              this.changeIcnObj = undefined;
            }
            this.waiting4Response = false;
          }

          if (dataSet[0]?.sqlProcNm?.startsWith('spMB_Sio_Get835FileDetail')) {
            if (dataSet[0]?.sqlProcNm == 'spMB_Sio_Get835FileDetail') { // Only when totals are sent
              this.adjTotal = (+dataSet[0].tAdjN + +dataSet[0].tAdjP).toString();
              this.adjCnt = (+dataSet[0].tAdjNcnt + +dataSet[0].tAdjPcnt).toString();

              if (dataSet[0]?.rowCnt == '0') { // Query produced no results
                this._835cas = [];
                this._835svc = [];
                this._835adj = [];
                this.carcNotes = [];
                this.claimsPropsReduced = [];
                this.checkPrint = false;
                this.waiting4Response = false;
              }
            } else {
              if (this.activeSqlProcs.find(sp => sp.token === dataSet[0].token)) {
                if (dataSet[0].sqlProcNm == 'spMB_Sio_Get835FileDetail-fil') {
                  this._835fil = dataSet;
                  this.filID = this._835fil[0].filID;
                  this.filNm = this._835fil[0].filNm;
                  this.filDt = this._835fil[0].filDt;
                  this.filAmnt = this._835fil[0].filAmnt;
                  this.filPayerNm = this._835fil[0].filPayerNm;
                  this.filPayeeNm = this._835fil[0].filPayeeNm;
                  this.ckClaimDetailsLoaded();
                }
                if (dataSet[0].sqlProcNm == 'spMB_Sio_Get835FileDetail-ck') {
                  this._835ck = dataSet;
                  this.ckClaimDetailsLoaded();
                }
                if (dataSet[0]?.sqlProcNm == 'spMB_Sio_Get835FileDetail-cas') {
                  this._835cas = dataSet;
                  this.prevCredited = this.previouslyCredited();
                  this.setPendCnt();

                  // let adjT = 0;
                  this.adjCnt = '0';
                  this.reclCnt = '0';
                  this.pendCnt = this._835cas.length.toString();
                  this.casIndxFound = [];

                  this.totalsReclaimed835andMore();

                  this.reduceClaimsArrProps4search(dataSet);
                  this.ckClaimDetailsLoaded();
                }
                if (dataSet[0]?.sqlProcNm == 'spMB_Sio_Get835FileDetail-svc') {
                  this._835svc = dataSet;
                  this.pendCnt = this.unCreditedPaymentsCnt();
                  this.ckClaimDetailsLoaded();
                }
                if (dataSet[0]?.sqlProcNm == 'spMB_Sio_Get835FileDetail-adj') {
                  this._835adj = dataSet;

                  let codes: string;
                  this._835adj.forEach(obj => { // Pre-cache carc codes to be used later printing or looking up descs
                    if (!this.carcNotes?.some(ob => ob.code == obj.adjReasonCode)) {
                      if (codes && !codes.includes(obj.adjReasonCode)) {
                        codes += ',' + obj.adjReasonCode;
                      } else if (!codes) {
                        codes = obj.adjReasonCode;
                      }
                    }
                  });
                  this.getCarcDescrFromReasonCode(codes);
                  this.ckClaimDetailsLoaded();
                }

              } else {
                // The following yields: Cannot use KILL to kill your own process.
                // To be implemented if I can find and issue a QUERY CANCEL Tsql.
                // this._websocketService.sendChat('query', this.sn, "KILL " + dataSet[0].spid + ";");
                console.log('NOT FOUND');
              }
            }
          }

          if (dataSet[0]?.sqlProcNm === 'spMB_Sio_Match835Payments2cases') {
            if (this.activeSqlProcs.find(sp => sp.token === dataSet[0].token)) {
              this.waiting4Response = false;
              this.unMatchedClaimCnt = +this.unMatchedClaims();
              if (!this.shown) {
                this._appToastsService.updateDeadCenter(true); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
                this._appToastsService.show(this.engLang ? 'Click on heading "Claim" to re-sort to view unmatched claims first' : 'Clic en encabezado "Caso" para reordenar y ver casos no pareados primero', { header: this.engLang ? 'Unidentified payment(s)' : 'Pago(s) no identificados', autohide: false, warning: true });
                this.shown = true;
              }
            }
            this.setPendCnt();
          }
        }
      });
    }
  }

  ngAfterViewInit(): void {
    // this._vref.createEmbeddedView(this.ckRegClaimsPaymentDetailModal);
    this._vref.remove();  // Removes the initially appended template
    this._vref.createEmbeddedView(this.reclaimsModalTmpl);
    this._vref.remove();  // Removes the initially appended template
    this._vref.remove();  // Removes the initially appended template

    // Needed data lists & tables for CkReg CRUD.
    console.log('%c' + 'q ' + "Exec spMB_Sio_GetCkRegisterLists @sn = '" + this.sn
      + "', @userID ='" + this.userID
      + "', @component = 'CkRegisterComponent';", 'color: blue; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_GetCkRegisterLists @sn = '" + this.sn
      + "', @userID ='" + this.userID
      + "', @component = 'CkRegisterComponent';"); // Gets all lists & parameters in 1 shot
  }

  ngOnDestroy() {
    this.clearChecks();

    // Persist MRUs parameters to clients Sql DB.
    console.log('%c' + 'query ' + "Exec spMB_Sio_SetMRUsParams @sn = '" + this.sn
      + "', @userID ='" + this.userID
      + "', @component = 'CkRegisterComponent'"
      + ", @mruDtFrom = '" + this.srchFromDt
      + "', @mruDtTo = '" + this.srchToDt
      + "', @mruCkNo = '" + this.srchCkNo
      + "', @mruDtType = '" + this.srchDtType
      + "', @mruNpi = '" + this.srchNpi
      + "', @mruPayerID = '" + this.srchPayerID
      + "';", 'color: black; background: #90EE90; font-size: 12px');
    this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_SetMRUsParams @sn = '" + this.sn
      + "', @userID ='" + this.userID
      + "', @component = 'CkRegisterComponent'"
      + ", @mruDtFrom = '" + this.srchFromDt
      + "', @mruDtTo = '" + this.srchToDt
      + "', @mruCkNo = '" + this.srchCkNo
      + "', @mruDtType = '" + this.srchDtType
      + "', @mruNpi = '" + this.srchNpi
      + "', @mruPayerID = '" + this.srchPayerID
      + "';");

    this.sioSubscr.unsubscribe();
    console.log('%c' + 'UNSUSCRIBE-CkRegisterComponent', 'color: white; background: black; font-size: 10px');

    this._835fil = undefined;
    this._835ck = undefined;
    this._835cas = undefined;
    this._835svc = undefined;
    this.ckClaims = undefined;
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  onBlur_date(event): void {
    switch (event.target.id) {
      case 'srchFromDt':
        if (this.srchFromDt && !this.srchToDt) {
          this.srchToDt = this.srchFromDt;
        } else if (this.srchFromDt && this.srchToDt) {
          if (moment(this.srchToDt, 'YYYY-MM-DD').isBefore(moment(this.srchFromDt, 'YYYY-MM-DD'))) {
            this.srchToDt = this.srchFromDt;
          }
        }
        break;

      case 'srchToDt':
        if (!this.srchFromDt && this.srchToDt) {
          this.srchFromDt = this.srchToDt;
        } else if (this.srchFromDt && this.srchToDt) {
          if (moment(this.srchFromDt, 'YYYY-MM-DD').isAfter(moment(this.srchToDt, 'YYYY-MM-DD'))) {
            this.srchFromDt = this.srchToDt;
          }
        }
        break;

      default:
    }
  }

  onClick_srch(event: any): void {
    event.target.select();
  }

  onKeyup_escapeCkRegPayDetailModal(event): void {
    if (event.keyCode == 27) {
      event.stopPropagation();
      document.getElementById('ckRegDetailModalCloseBtn').click();
    }
  }

  onKeyUp_srch(event: any): void {
    if (event.key == 'Enter') {
      this.getCks();
    }
  }

  onKeyUp_ckRxDt(event): void {
    if (event.keyCode == 13) {
      event.stopPropagation();
      const momDt = moment(event.target.value, 'MM/DD/YY');
      if (!momDt.isValid()) {
        event.target.value = '';
      }
      event.target.blur(); // Loose focus on date input upon Enter
    }
  }

  getCks(): void {
    if (!this.invalidFrToDts(this.srchFromDt, this.srchToDt)) {
      console.log('%c' + 'getCks() q = ' + "EXEC spMB_Sio_GetCkRegister @sn = '" + this.sn
        + "', @rxDt='" + this.srchDtType + "'"
        + (this.srchFromDt ? ", @fromDt='" + this.srchFromDt.replace(/-*/g, '') + "'" : '')
        + (this.srchToDt ? ", @toDt='" + this.srchToDt.replace(/-*/g, '') + "'" : '')
        + (this.srchCkNo ? ", @ckgNo='" + this._helpRtns.escApos(this.srchCkNo) + "'" : '') + ';'
        , 'color: black; background: #90EE90; font-size: 12px');

      this.waiting4Response = true;
      this.clearChecks();
      this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_GetCkRegister @sn = '" + this.sn
        + "', @rxDt='" + this.srchDtType + "'"
        + (this.srchFromDt ? ", @fromDt='" + this.srchFromDt.replace(/-*/g, '') + "'" : '')
        + (this.srchToDt ? ", @toDt='" + this.srchToDt.replace(/-*/g, '') + "'" : '')
        + (this.srchCkNo ? ", @ckgNo='" + this._helpRtns.escApos(this.srchCkNo) + "'" : '') + ';');
    }
  }

  invalidFrToDts(fr: string, to: string): boolean {
    const momFr = moment(fr, 'MM/DD/YY');
    const momTo = moment(to, 'MM/DD/YY');
    if (momFr.isValid() && momTo.isValid()) {
      if (momFr.isAfter(momTo)) {
        return true;
      }
    }
    return false;
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
    this.sio = this.sn.length > 4 ? false : true;
  }

  userIdChange(userIdStr) {
    this.userID = userIdStr;
  }

  siteChange(siteStr: string) { // for when site changes
    this.siteNm = siteStr;
  }

  siteAd1Change(siteAd1Str: string) { // for when site changes
    this.siteAd1 = siteAd1Str;
  }

  siteAd2Change(siteAd2Str: string) { // for when site changes
    this.siteAd2 = siteAd2Str;
  }

  siteCtChange(siteCtStr: string) { // for when site changes
    this.siteCt = siteCtStr;
  }

  siteStChange(siteStStr: string) { // for when site changes
    this.siteSt = siteStStr;
  }

  siteZpChange(siteZpStr: string) { // for when site changes
    this.siteZp = siteZpStr;
  }

  siteTl1Change(siteTl1Str: string) { // for when site changes
    this.siteTl1 = siteTl1Str;
  }

  siteXt1Change(siteXt1Str: string) { // for when site changes
    this.siteXt1 = siteXt1Str;
  }

  siteTl2Change(siteTl2Str: string) { // for when site changes
    this.siteTl2 = siteTl2Str;
  }

  siteXt2Change(siteXt2Str: string) { // for when site changes
    this.siteXt2 = siteXt2Str;
  }

  onClick_showPayedClaimsDetails(ckid: string): void {
    if (ckid) {
      const ck = this.checks.find(c => c.ckgID === ckid);
      this.chkgDetID = ck.ckgID;
      this.chkgNo = ck.ckgNo;
      this.chkgDt = ck.ckDt;
      this.showChildPayments = true;
    }
  }

  updtCkCreditedAmnt(event: { pay: string, children: string }) {
    let payDeletedAmnt = event.pay;
    let ckChildren = event.children;
    let ck = this.checks.find(ele => ele.ckgID == this.chkgDetID);
    ck.ckCredited = (parseFloat(ck.ckCredited) - parseFloat(payDeletedAmnt)).toFixed(2);
    ck.ckBalance = (parseFloat(ck.ckTotal) - parseFloat(ck.ckCredited)).toFixed(2);
    ck.ckChildren = ckChildren; // # of Payments allocated to this ck
    this.calcTotals();
  }

  calcTotals(): void {
    this.totals.total = 0;
    this.totals.credited = 0;
    this.totals.balance = 0;
    this.totals.count = 0;
    this.checks?.forEach(ck => {
      if (!ck.hide) {
        this.totals.total += parseFloat(ck.ckTotal.replace(/,/g, ''));
        this.totals.credited += parseFloat(ck.ckCredited.replace(/,/g, ''));
        this.totals.balance += parseFloat(ck.ckBalance.replace(/,/g, ''));
        this.totals.count += 1;
      }
    });

  }

  totalsReclaimed835andMore(): void {
    this.reclTotal = '0';
    this.reclCnt = '0';

    this._835cas.forEach(e => {
      this.casIndxFound.push(e.indx); // Initially contais all visible claims
      if (e?.casRecled == '1') {
        this.reclCnt = (1 + parseInt(this.reclCnt)).toString();
        this.reclTotal = (+this.reclTotal + +e.casBal).toFixed(2);
      }

      if (e.casPS == '1') {
        e.casPsDesc = 'Pri';
      } else if (e.casPS == '2') {
        e.casPsDesc = 'Sec';
      } else {
        e.casPsDesc = '???';
      }
    });
  }

  isPending(balance): boolean {
    if (parseFloat(balance) > 0) {
      return true;
    }
    return false;
  }

  onFocus_decimal(event: any) {
    if (event) {
      event.target.value = event.target.value.replace(/[,]*/g, '').trim();
    }
  }

  onKeyPress_decimal(event: any, p): void {
    if (event) {
      let monyRegex = /^[-]?\d{0,6}[.]?\d{0,2}$/g;
      if (event.key == 'Enter' && event.target.value && event.target.value.match(monyRegex)) {
        // let icnInput = event.target.parentElement.parentElement.parentElement.nextSibling.children[0].children[0];
        let icnInput = document.getElementById('icn' + p);
        icnInput.focus();
        return;
      }
      if ((event.key >= '0' && event.key <= '9')
        || (event.key == '.' && !event.target.value.match(/[.]/g))
        || (event.key == '-' && event.target.selectionStart == 0 && (event.key + event.target.value).match(monyRegex))) {
        return;
      } else {
        event.preventDefault(); // Cancel invalid key pressed.
      }
    }
  }

  onBlur_setDecimalValue(event: any, p): void {
    if (event && event.target.value) {
      event.target.value = event.target.value.replace(/[,]*/g, '').trim();
      event.target.value = this._helpRtns.fmtAmount(event.target.value, '', 2);
      this.procs[p].payment = event.target.value;
    }
  }

  onFocus_icn(event: any, p): void {
    if (p > 0 && this.procs[p].icn.trim() == '' && this.procs[p].payment.match(/\d*[.?\d*]/g)) {
      this.procs[p].icn = this.procs[p - 1].icn;
    }
  }

  onKeyPress_icn(event: any, p): void {
    if (event?.key === 'Enter' || event?.key === 'Tab') {
      let savOut = false;  // Save and out
      if (!event.shiftKey) {  // Tab or Enter
        if (event.target.id === 'icn' + (this.procs.length - 1).toString()) { // Focus on las input element
          savOut = true;
        }
        if (event.key === 'Enter') {
          p += 1;
          if (p >= this.procs.length) {
            savOut = true;
          } else {
            (<HTMLInputElement>document.querySelector('#pay' + p.toString())).focus();
          }
        }
      } else {
        if (event.target.id === 'chk0') { // Focus on very first input elemen
          savOut = true;
        }
      }

      if (savOut) {
        this.savedPaymentsCnt = 0;
        this.savePayments();  // Will save if payments entered
        this.procs = undefined; // Close payment entry view
      }
    }
  }

  onClick_deleteCheck(): void {
    console.log('%c' + 'CkRegisterComponent.deleteCheck query = EXEC spMB_Sio_DeleteCheck @ckgID=' + this.ckgID + ';', 'color: black; background: #90EE90; font-size: 12px');
    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_DeleteCheck @ckgID='" + this.ckgID + "';");
  }

  onClick_addCheck(): void {
    this.addCkSuccess = undefined;
    this.addCkErr = undefined;
    let ckDt: string;
    if (!this.chkgDt || moment(this.chkgDt, 'MM/DD/YY').isAfter(moment())) {
      let tdt = moment().format('MM/DD/YY');
      this.addCkErr = (this.engLang ? 'Check pay date. Should not be higher than today ' + tdt + ', nor be empty.' : 'Coteje fecha del pago. No debe ser mayor que hoy ' + tdt + ', ni estar vacía.');
    } else {
      ckDt = moment(this.chkgDt, 'MM/DD/YY').format('YYYYMMDD')
    }

    if (!this.chkgNo) {
      this.addCkErr = this.addCkErr ? this.addCkErr + '\n' + (this.engLang ? 'Enter check or transfer number.' : 'Entre número del Cheque o Transferencia Electrónica.')
        : (this.engLang ? 'Enter check or transfer number.' : 'Entre número del Cheque o Transferencia Electrónica.');
    }

    if (!this.chkgTotalInp || !this.chkgTotalInp.match(/^\d*[.]?\d{0,2}$/g)) {
      this.addCkErr = this.addCkErr ? this.addCkErr + '\n' + (this.engLang ? 'Verify payment total.' : 'Coteje cantidad total del pago.')
        : (this.engLang ? 'Verify payment total.' : 'Coteje cantidad total del pago.');
    }

    if (this.chkgTaxDedInp && !this.chkgTaxDedInp.match(/^\d*[.]?\d{0,2}$/g)) {
      this.addCkErr = this.addCkErr ? this.addCkErr + '\n' + (this.engLang ? 'Verify tax deduction.' : 'Coteje cantidad deducción Hacienda.')
        : (this.engLang ? 'Verify tax deduction.' : 'Coteje cantidad deducción Hacienda.');
    }

    if (this.chkgMedDedInp && !this.chkgMedDedInp.match(/^\d*[.]?\d{0,2}$/g)) {
      this.addCkErr = this.addCkErr ? this.addCkErr + '\n\n\n' + (this.engLang ? 'Verify health plan deduction.' : 'Coteje cantidad deducción plan médico.')
        : (this.engLang ? 'Verify health plan deduction.' : 'Coteje cantidad deducción plan médico.');
    }

    if (this.chkgOtherDedInp && !this.chkgOtherDedInp.match(/^\d*[.]?\d{0,2}$/g)) {
      this.addCkErr = this.addCkErr ? this.addCkErr + '\n\n\n' + (this.engLang ? 'Verify other deduction.' : 'Coteje cantidad otra deducción.')
        : (this.engLang ? 'Verify other deduction.' : 'Coteje cantidad otra deducción.');
    }

    if (this.addCkErr) {
      this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
      this._appToastsService.show(this.addCkErr, { header: 'Error', autohide: true, error: true });
      return;
    }

    console.log('%c' + "CkRegisterComponent.addCheck query = EXEC spMB_Sio_AddCheck "
      + "@sn = '" + this.sn
      + "', @ckgNo='" + this.chkgNo
      + "', @cKgDate = '" + ckDt
      + "', @ckgPaymnt = '" + this.chkgTotalInp
      + "'" + (this.chkgTaxDedInp ? ", @ckgTaxDed = '" + this.chkgTaxDedInp + "'" : "")
      + (this.chkgMedDedInp ? ", @ckgHIDed = '" + this.chkgMedDedInp + "'" : "")
      + (this.chkgOtherDedInp ? ", @ckgOtherDed = '" + this.chkgOtherDedInp + "'" : "")
      + (this.chkgNPI ? ", @ckgNPI = '" + this.chkgNPI + "'" : "")
      + (this.chkgPayerId ? ", @ckgPayerId = '" + this.chkgPayerId + "'" : "")
      + (this.chkgRxDt ? ", @ckgRxDate = '" + this._helpRtns.fmtDate(new Date(this.chkgRxDt), 'ymd') + "'" : "")
      + ";", 'color: black; background: #90EE90; font-size: 12px');

    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_AddCheck "
      + "@sn = '" + this.sn
      + "', @ckgNo='" + this.chkgNo
      + "', @cKgDate = '" + ckDt
      + "', @ckgPaymnt = '" + this.chkgTotalInp
      + "'" + (this.chkgTaxDedInp ? ", @ckgTaxDed = '" + this.chkgTaxDedInp + "'" : "")
      + (this.chkgMedDedInp ? ", @ckgHIDed = '" + this.chkgMedDedInp + "'" : "")
      + (this.chkgOtherDedInp ? ", @ckgOtherDed = '" + this.chkgOtherDedInp + "'" : "")
      + (this.chkgNPI ? ", @ckgNPI = '" + this.chkgNPI + "'" : "")
      + (this.chkgPayerId ? ", @ckgPayerId = '" + this.chkgPayerId + "'" : "")
      + (this.chkgRxDt ? ", @ckgRxDate = '" + this.chkgRxDt.replace(/-*/g, '') + "'" : "")
      + ";");
  }

  onChange_selectNPI(event): void {
    this.clearPoppers();
    this.chkgNPI = this.provsNPI[event.target.selectedIndex].ckgNpi;
  }

  onChange_selectPayerID(event): void {
    this.clearPoppers();
    this.chkgPayerId = this.insPayerID[event.target.selectedIndex].insPayID;
  }

  onChange_setPayedEqXpect(event, p, proc): void {
    let chk = <HTMLInputElement>event.target;
    let payEle = <HTMLInputElement>document.getElementById('pay' + p);
    if (chk.checked) {
      let xpectTxt = document.getElementById('xpt' + p).textContent;
      proc.payment = xpectTxt;
      if (p > 0) {
        let icnEle = document.getElementById('icn' + (p - 1));
        proc.icn = icnEle.textContent;
      }
      document.getElementById('icn' + p).focus();
    } else {
      payEle.value = '';
      document.getElementById('pay' + p).focus();
    }
  }

  clearCkRegAddInputs(): void {
    this.clearPoppers();
    this.ckgID = undefined;
    this.chkgNo = undefined;
    this.chkgDt = '';
    this.chkgRxDt = '';
    this.chkgTotal = undefined;
    this.chkgTotalInp = undefined;
    this.chkgTaxDed = undefined;
    this.chkgTaxDedInp = undefined;
    this.chkgMedDed = undefined;
    this.chkgMedDedInp = undefined;
    this.chkgOtherDed = undefined;
    this.chkgOtherDedInp = undefined;
    let npi = <HTMLSelectElement>document.querySelector('#chkgNPI');
    npi ? npi.value = undefined : undefined;
    let payr = <HTMLSelectElement>document.querySelector('#chkgPayerId');
    payr ? payr.value = undefined : undefined;
    this.addCkErr = undefined;
    this.addCkSuccess = undefined;
  }

  clearChecks(): void {
    this.checks = [];
    this.ckSelectedId = -1;
  }

  onClick_showCreditPaymntsModal(): void {
    if (this.selectedCk) {
      this.adjCnt = '0';
      this.adjTotal = '0';
      this.reclCnt = '0';
      this.reclTotal = '0';
      this.claimSrchActive = false;
      this.claimsAdjustedSrchActive = false;
      this.reclaimedSrchActive = false;
      this.claimsPendingSrchActive = false;
      this.claimsPropsReduced = [];

      this.ckgID = this.selectedCk.ckgID;
      this.chkgNo = this.selectedCk.ckgNo;
      this.chkgNPI = this.selectedCk.ckNPI;
      this.chkgDt = this.selectedCk.ckDt;
      this.chkgBalance = this.selectedCk.ckBalance;
      let chkZeroBal = <HTMLInputElement>document.getElementById('chkZeroBal');
      let toDt = moment(this.chkgDt, 'MM/DD/YY').format('YYYYMMDD');
      let fromDt = ((moment(this.chkgDt, 'MM/DD/YY')).subtract(366 * 2, "days")).format('YYYYMMDD');

      console.log('%c' + "CkRegisterComponent.onClick_showCreditPaymntsModal q = EXEC spMB_Sio_ClaimCandidates4CkRegPayments "
        + "@sn = '" + this.sn + "', @fromDt = '" + fromDt + "', @toDt = '" + toDt + "'"
        + (this.selectedCk.ckNPI ? ", @prvNPI = '" + this.selectedCk.ckNPI + "'" : "")
        + (this.selectedCk.ckPayerID ? ", @insPayrID = '" + this.selectedCk.ckPayerID + "'" : "")
        + (this.selectedCk.ckgNo ? ", @ckNo = '" + this.selectedCk.ckgNo + "'" : "")
        + (chkZeroBal && chkZeroBal.checked ? ", @zeroBal = 1" : "")
        + ";", 'color: black; background: #90EE90; font-size: 12px');

      this.waiting4Response = true;
      this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_ClaimCandidates4CkRegPayments "
        + "@sn = '" + this.sn + "', @fromDt = '" + fromDt + "', @toDt = '" + toDt + "'"
        + (this.selectedCk.ckNPI ? ", @prvNPI = '" + this.selectedCk.ckNPI + "'" : "")
        + (this.selectedCk.ckPayerID ? ", @insPayrID = '" + this.selectedCk.ckPayerID + "'" : "")
        + (this.selectedCk.ckgNo ? ", @ckNo = '" + this.selectedCk.ckgNo + "'" : "")
        + (chkZeroBal && chkZeroBal.checked ? ", @zeroBal = 1" : "")
        + ";");
    }
  }

  onClick_showProcDetail(casID): void {
    if (this.procs && this.procs.length > 0) {
      if (this.procs[0].detCasID == casID) {  // Clickin on same opened claim then toggle close it
        this.savedPaymentsCnt = 0;
        this.savePayments();
        this.procs = undefined; // Close payment entry view
        return;
      }
    }

    console.log('%c' + "CkRegisterComponent.onClick_showProcDetail query = EXEC spMB_Sio_ClaimCandidates4CkRegPaymentsDetail "
      + "@casID = '" + casID + "';", 'color: black; background: yellow; font-size: 12px');

    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_ClaimCandidates4CkRegPaymentsDetail "
      + "@sn = '" + this.sn
      + "', @casID = '" + casID + "';");
  }

  search4AdjustedClaims(): void {

  }

  setCasIndxFound(casIndxFound): void {
    this.casIndxFound = casIndxFound;
    this.currentPageClms = 1; // Initialize for pagination or it will show a blank page if found item ws initially on different page
  }

  setClaimSrchActive(claimSrchActive): void {
    this.claimSrchActive = claimSrchActive
  }

  setClaimsAdjustedSrchActive(claimsAdjustedSrchActive): void {
    this.claimsAdjustedSrchActive = claimsAdjustedSrchActive;
  }

  setReclaimedSrchActive(reclaimedSrchActive): void {
    this.reclaimedSrchActive = reclaimedSrchActive;
    this.currentPageCas = 1;
  }

  setClaimsPendingSrchActive(claimsPendingSrchActive): void {
    this.claimsPendingSrchActive = claimsPendingSrchActive;
  }

  toggleShowZeroBal(event): void {
    if (event.clrZeroBal) {
      this.showZeroBal = false;
      return;
    }

    this.showZeroBal = event.showZeroBal;
    this.adjTotal = '0';
    if (event.filNm && event.filDt) {
      const ck: ICheck = {
        ckgID: '',
        ckDt: this.chkgDt,
        ckgNo: this.chkgNo,
        ckTotal: this.chkgBalance,
        ckCredited: this.chkgCredited,
        ckBalance: this.chkgBalance,
        ckNPI: this.chkgNPI,
        ckPayerID: this.chkgPayerId,
        ckPayerID835: '',
        ckRxDt: '',
        ckFilNm: event.filNm,
        ckFilDt: event.filDt,
        ckChildren: '',
        ckPos: '',
        hide: false
      };
      this.onClick_get835file(ck, false);
    } else {
      this.onClick_showCreditPaymntsModal();
    }
    this.currentPageClms = 1;
  }

  savePayments(mode?: string): void {
    let insrtPayQry = '';
    for (let i = 0; i < this.procs.length; i++) {
      let pay = this.procs[i].payment.replace(/^\D*$/g, '');
      let ps = 'S' + this.procs[i].detPS;
      if (pay && ps.match(/^S(1|2)$/g)) {
        insrtPayQry += "EXEC spMB_Sio_SaveCkPayment "
          + "@sn = '" + this.sn
          + "', @ckgID = '" + this.ckgID
          + "', @payCasID = '" + this.procs[0].detCasID
          + "', @payDt = '" + moment(this.chkgDt, 'MM/DD/YY').format('YYYYMMDD')
          + "', @payAmnt = '" + pay
          + "', @payCkNo = '" + this._helpRtns.escApos(this.chkgNo)
          + "', @payPS = '" + ps
          + "', @payApCode = '" + this._helpRtns.escApos(this.procs[i].icn)
          + "', @payMode = '" + (mode ? mode : 'Voucher')
          + "', @payDetID = '" + this.procs[i].detID
          + "', @payDtFmt = '" + moment(this.chkgDt, 'MM/DD/YY').format('MM/DD/YYYY')
          + "', @payUserID = '" + this.userID
          + (this.procs[i].payCkSeqNo ? "', @payCkSeqNo = '" + this.procs[i].payCkSeqNo : "")
          + (this.procs[i].casSeqNo ? "', @casSeqNo = '" + this.procs[i].casSeqNo : "")
          + (this.procs[i].fromDt ? "', @detFrom = '" + this.procs[i].fromDt : "")
          + (this.procs[i].detPcode ? "', @detPcode = '" + this.procs[i].detPcode : "")
          + (this.procs[i].mods ? "', @detMods = '" + this.procs[i].mods : "")
          + (this.procs[i].detQty ? "', @detQty = '" + this.procs[i].detQty : "")
          + (this.procs[i].svcPos ? "', @svcPos = '" + this.procs[i].svcPos : "")
          + (this.procs[i].casPos ? "', @casPos = '" + this.procs[i].casPos : "")
          + (this.procs[i].ckPos ? "', @ckPos = '" + this.procs[i].ckPos : "")
          + (this.filID ? "', @filID = '" + this.filID : "")
          + "', @last = '0"
          + "';"
      }
    }

    if (insrtPayQry) {
      console.log('%c' + "CkRegisterComponent.savePayments q = " + insrtPayQry, 'color: black; background: #90EE90; font-size: 12px');
      this.waiting4Response = true;
      this._websocketService.sendChat('query', this.sn, insrtPayQry);
    }
  }

  undoPayments(claim: any): void {
    for (let i = this.undoPaysArr.length - 1; i >= 0; i--) { // Backwards to undo last payment first
      if (this.undoPaysArr[i].payCasID == claim.casID && this.undoPaysArr[i].ps == claim.ps) {
        let adjDetID: string = this.ckClaims.find(cl => cl.casID == claim.casID && cl.ps == claim.ps).adjDetID;
        console.log('%c' + "CkRegisterComponent.undoPayments q = "
          + "EXEC spMB_Sio_UndoCkPayment "
          + "@sn = '" + this.sn
          + "', @payCasID = '" + claim.casID
          + "', @ps = '" + claim.ps
          + "', @payID = '" + this.undoPaysArr[i].payID
          + "', @payAmnt = '" + this.undoPaysArr[i].payAmnt
          + "', @payDt = '" + this.undoPaysArr[i].payDt
          + "', @adjDetID = '" + adjDetID
          + "', @balance = '" + claim.balance
          + "', @ckgID = '" + this.ckgID
          + "', @payCkNo = '" + this.undoPaysArr[i].payCkNo
          + "';"
          , 'color: black; background: yellow; font-size: 12px');

        this.waiting4Response = true;
        this._websocketService.sendChat('query', this.sn, "EXEC spMB_Sio_UndoCkPayment "
          + "@sn = '" + this.sn
          + "', @payCasID = '" + claim.casID
          + "', @ps = '" + claim.ps
          + "', @payID = '" + this.undoPaysArr[i].payID
          + "', @payAmnt = '" + this.undoPaysArr[i].payAmnt
          + "', @payDt = '" + this.undoPaysArr[i].payDt
          + "', @adjDetID = '" + adjDetID
          + "', @balance = '" + claim.balance
          + "', @ckgID = '" + this.ckgID
          + "', @payCkNo = '" + this.undoPaysArr[i].payCkNo
          + "';");

        break;
      }
    }
  }

  payment2delete(): string {
    if (this.undoPaysArr.length) {
      let i = this.undoPaysArr.length - 1;
      let dtMom = moment(this.undoPaysArr[i].payDt, 'YYYYMMDD');
      let dt = '';
      if (dtMom.isValid) {
        dt = dtMom.format('MM/DD/YY');
      }
      return dt + '\t $' + this.undoPaysArr[i].payAmnt;
    }
  }

  onMouseenter_setClaimToolTip(casID): string {
    return this.engLang ? 'Click desired claim to enter payment.' : 'Clic en caso deseado para entrar pagos.';
  }

  caseAdjust(casID, casProvID, ps): void {
    let adjQry = "EXEC spMB_Sio_CaseAdjust "
      + "@sn = '" + this.sn
      + "', @casID = '" + casID
      + "', @payDt = '" + moment(this.chkgDt, 'MM/DD/YY').format('YYYYMMDD')
      + "', @provID = '" + casProvID
      + "', @ps = '" + ps
      + "', @payCkNo = '" + this._helpRtns.escApos(this.chkgNo)
      + "', @payDtFmt = '" + moment(this.chkgDt, 'MM/DD/YY').format('MM/DD/YYYY')
      + "', @payUserID = '" + this.userID
      + "', @payType = '" + 'man'
      + "';"

    console.log('%c' + "CkRegisterComponent.caseAdjust q = " + adjQry, 'color: black; background: #90EE90; font-size: 12px');
    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, adjQry)
  }

  caseAdjustRemove(casID, adjDetID, ps): void {
    let adjQry = "EXEC spMB_Sio_CaseAdjustRemove "
      + "@sn = '" + this.sn
      + "', @casID = '" + casID
      + "', @detID = '" + adjDetID
      + "', @ps = '" + ps
      + "', @payType = '" + 'man'
      + "';"

    console.log('%c' + "CkRegisterComponent.caseAdjustRemove q = " + adjQry, 'color: black; background: yellow; font-size: 12px');
    this.waiting4Response = true;
    this._websocketService.sendChat('query', this.sn, adjQry)
  }

  reclaim835(cas): void { // This rtn is analogous to onChange_caseReclaim(...) & vice versa
    // console.log('click on reclaim835(cas)');
    // console.log('cas', cas);
    this.reclCasIdSeled = cas.casID;
    this.reclPsSeled = cas.casPS;
    this.reclInsID = cas.insID;
    this.reclFormTypSeled = cas.casReclTyp;
    this.casReclTyp = cas.casReclTyp; // Preserve case default form type value
    this.waiting4Response = true;
    console.log('this.open(this.reclaimsModalTmpl, ...');
    this.open(this.reclaimsModalTmpl, { backdrop: 'static', size: 'xl', scrollable: true, keyboard: false }, 'reclaimsModalTmpl');
  }

  onChange_caseReclaim(event, casID, casPS, casReclTyp, reclInsID): void {
    if (event?.target) {
      event.preventDefault(); // Don't toggle yet, wait for saved result
      event.target.checked = !event.target.checked;
      this.clmSelectedIndex = this.ckClaims.findIndex(clm => clm.casID == casID && clm.ps == casPS);
      this.reclCasIdSeled = casID;
      this.reclPsSeled = casPS;
      this.reclInsID = reclInsID;
      this.reclFormTypSeled = casReclTyp;
      this.casReclTyp = casReclTyp; // Preserve case default form type value
      this.waiting4Response = true;
      this.open(this.reclaimsModalTmpl, { backdrop: 'static', size: 'xl', scrollable: true, keyboard: false }, 'reclaimsModalTmpl');
    }
  }

  onChange_caseAdjust(c, clm): void {
    this.adjClm = clm;  // Holds current claim
    let chkAdj = <HTMLInputElement>document.querySelector('#chkAdj' + c.toString());
    if (chkAdj.checked) {
      this.adjustRemove = false;  // adjust
      chkAdj.checked = false; // Revert until adjustment saved
    } else {
      this.adjustRemove = true;  // remove adjust
      chkAdj.checked = true;  // Revert until adjustment removed
    }
    this.adjustPromptModal = true;
  }

  onChange_payUndo(c, clm): void {
    this.adjClm = clm;  // Holds current claim
    this.adjustRemove = false;
    this.undoPayment = true;
    this.adjustPromptModal = true;
  }

  adjustModalResult(event) {
    this.adjustPromptModal = false;
    console.log('event', event);
    console.log('adjClm', this.adjClm);
    if (event == 'ok') {
      if (this.undoPayment) {
        this.undoPayments(this.adjClm);
      } else if (this.adjustRemove) {
        this.caseAdjustRemove(this.adjClm.casID, this.adjClm.adjDetID, this.adjClm.ps);
      } else {
        this.caseAdjust(this.adjClm.casID, this.adjClm.casProvID, this.adjClm.ps);
      }
    }
    this.adjustRemove = false;
    this.undoPayment = false;
  }

  closeReclaimComponent(event): void {
    if (event) {
      this.changeIcnObj = event;
    } else {
      this.changeIcnObj = undefined;
    }
    this.closeReclaimsModal();
  }

  open(content, opts, modalNm, obj?: any) {
    this.clearPoppers();
    this.modalStack.push(modalNm);

    this.modalRef = this._modalService.open(content, opts);
    if (content === this.reclaimsModalTmpl) {
      this.reclaimsModalRef = this.modalRef;
    }

    this.modalRef.result.then((result) => {
      console.log(this.modalStack);
      console.log('%c' + "modal closed result=" + result, 'color: black; background: yellow; font-size: 14px');
      if (result == 'closeClaimsModal') {
        this.ckClaims = undefined;
        this.undoPaysArr = [];
        this.modalStack.pop();
        return;
      }
      if (result === 'oK_deleteCheck') {
        this.onClick_deleteCheck();
        this.modalStack.pop();
        return;
      }
      if (result === 'oK_addCheck') {
        this.onClick_addCheck();
        this.modalStack.pop();
        this.open(content, { backdrop: 'static' }, 'addCkModal')  // Maintains modal openned
      }
      if (result === 'oK_exitWoSaveReclaimModal') {
        this.modalRef.dismiss();
        this.modalStack.pop();
        this.reclaimsModalRef.dismiss();
      }
      if (result === 'oK_claimAdjustConfirmModal') {
        debugger;
        if (this.adjustRemove) {
          this.caseAdjustRemove(obj.casID, obj.adjDetID, obj.ps);
        } else if (this.undoPayment) {
          this.undoPayments(obj);
        } else {
          const bal = this.ckClaims.find(c => c.casID === obj.casID && obj.ps === c.ps);
          if (+bal.balance) { // Only ajust to 0 non zero balances
            this.caseAdjust(obj.casID, obj.casProvID, obj.ps);
          }
        }
        this.modalStack.pop();
      }
      if (result === '_835fileClaimsPaymentDetailModal') {
        this.modalStack.pop();
      }
      if (result === 'oK_credit835Payments') {
        this.modalStack.pop();
        if (!this.finished) {
          this.waiting4Response = true;
          if (this.unCreditedPaymentsCnt() > '0') {
            this.credit835Payments();
            this.open(content, { backdrop: 'static' }, 'save835paymentsModal'); // Maintains modal openned
          }
        }
        this.unCredPayCnt = '0';
        this.finished = false;
      }
    }, (reason) => {  // Dimiss

      console.log(this.modalStack);
      console.log('%c' + "modal dismissed reason=" + reason, 'color: black; background: yellow; font-size: 14px');
      switch (this.modalStack[this.modalStack.length - 1]) {
        case 'addCkModal':
          break;
        case 'claimsModal':
          this.ckClaims = undefined;
          this.undoPaysArr = [];
          this.showZeroBal = false;
          this.currentPageClms = 1;
          break;
        case 'reclaimsModalTmpl':
          this.reclIdxSeld = -1;
          this.reclFormTypSeled = undefined;
          break;
        case 'reclaimDeleteModal':
          break;
        case 'reclaimsExitWoSaveModal':  // Stay in selected reclaim
          // console.log('reclaimsExitWoSaveModal', 'ESCAPED');
          // console.log(this.modalStack);
          break;
        case '_835fileClaimsPaymentDetailModal':
          this.unMatchedClaimCnt = 0;
          let i = this.activeSqlProcs.findIndex(obj => obj.sp === 'spMB_Sio_Get835FileDetail');
          if (i > -1) {
            this.activeSqlProcs.splice(i, 1);
          }
          i = this.activeSqlProcs.findIndex(obj => obj.sp === 'spMB_Sio_Match835Payments2cases');
          if (i > -1) {
            this.activeSqlProcs.splice(i, 1);
          }
          this.shown = false;
          break;
        case 'save835paymentsModal':
          this.finished = false;
          break;
        case 'credit835Payments':
          this.finished = false;
          break;
        default:
      }

      this.modalStack.pop();
      this.waiting4Response = false;
    });
  }

  open2(content, opts) {  // Independent promise to avoid conflict with open(...)
    this._modalService.open(content, opts).result.then((result) => {
      if (result === 'oK_exitWoSaveReclaimModal') {
        this.enabSaveRecl = false;
        if (this.changeIcnObj) {
          this.reclIdxSeld = this.changeIcnObj.nxtIcnIdx; // This will change idxSeld all the way down to the reclaim form component
          this.reclID = this.changeIcnObj.nxtReclID;
          this.icnsPrevIdx = -1;
          this.changeIcnObj = undefined;
        } else {
          this.reclaimsModalRef.dismiss();
        }
      } else {
        this.reclaimsModalRef.dismiss();
      }
    }, (reason) => {  // Dimiss
      if (reason === 'closeReclaimExitWoSaveModal') {
        console.log('this.icnsPrevIdx', this.icnsPrevIdx);
        this.icnsPrevIdx = this.changeIcnObj ? (this.changeIcnObj.prvIcnIdx > -1 ? this.changeIcnObj.prvIcnIdx : -1) : -1;
        console.log('this.icnsPrevIdx', this.icnsPrevIdx);
      }
    });
  }

  resetIcnsPrevIdx(event) {
    this.icnsPrevIdx = -1;
  }

  onClick_showPopr1(): void {
    this.showPopr1 = !this.showPopr1;
    this.showPopr2 = false;
    this.showPopr3 = false;
  }

  onClick_showPopr2(): void {
    this.showPopr1 = false;
    this.showPopr2 = !this.showPopr2;
    this.showPopr3 = false;
  }

  onClick_showPopr3(): void {
    this.showPopr1 = false;
    this.showPopr2 = false;
    this.showPopr3 = !this.showPopr3;
  }

  clearPoppers(): void {
    this.showPopr1 = false;
    this.showPopr2 = false;
    this.showPopr3 = false;
  }

  highlightSelected(r): string {
    if (this.reclRwIdxSeld > -1 && r == this.reclRwIdxSeld) {
      return 'rowSelected';
    } else {
      if (r % 2) {
        return 'oddReclSrch';
      } else {
        return 'evenReclSrch';
      }
    }
  }

  sortClaims(id): void {
    let sortDirSet = false; // True when this.sortAsc is set
    let th = <HTMLTableCellElement>document.querySelector('#' + id);  // Clicked th
    let prevDiv;  // Current visible div for sorted column 

    let div1 = <HTMLDivElement>document.getElementById('nmClmThSrt');
    let div2 = <HTMLDivElement>document.getElementById('dtClmThSrt');
    let div3 = <HTMLDivElement>document.getElementById('contrClmThSrt');
    let div4 = <HTMLDivElement>document.getElementById('casClmThSrt');

    if (!div1.hidden) {
      prevDiv = div1;
    } else if (!div2.hidden) {
      prevDiv = div2;
    } else if (!div3.hidden) {
      prevDiv = div3;
    } else if (!div4.hidden) {
      prevDiv = div4;
    }

    sortDirSet = this.displayCasSortArrow(prevDiv, div1, th, sortDirSet);
    sortDirSet = this.displayCasSortArrow(prevDiv, div2, th, sortDirSet);
    sortDirSet = this.displayCasSortArrow(prevDiv, div3, th, sortDirSet);
    sortDirSet = this.displayCasSortArrow(prevDiv, div4, th, sortDirSet);

    let arrowDiv = <HTMLDivElement>document.getElementById(th.id + 'Srt');
    arrowDiv.hidden = false; // Unhide arrow for clicked header

    const claims2sort = [...this.ckClaims]; // New independent array
    this.ckClaims = []; // Needed to trigger change so that cas-found pipe re-activates

    switch (id) {
      case 'nmClmTh':
        if (this.sortAsc) {
          claims2sort.sort(this.clmSortByNmAsc);
        } else {
          claims2sort.sort(this.clmSortByNmDesc);
        }
        break;
      case 'dtClmTh':
        if (this.sortAsc) {
          claims2sort.sort(this.clmSortByDtAsc);
        } else {
          claims2sort.sort(this.clmSortByDtDesc);
        }
        break;
      case 'contrClmTh':
        if (this.sortAsc) {
          claims2sort.sort(this.clmSortByContrDesc);
        } else {
          claims2sort.sort(this.clmSortByContrAsc);
        }
        break;
      case 'casClmTh':
        if (this.sortAsc) {
          claims2sort.sort(this.clmSortByCasAsc);
        } else {
          claims2sort.sort(this.clmSortByCasDesc);
        }
    }

    this.ckClaims = claims2sort;  // Causes re-rendering of table after sort
    this.currentPageClms = 1;
  }

  clmSortByNmAsc(clm1, clm2): number {
    if (clm1.patLstNm + ' ' + clm1.patNm > clm2.patLstNm + ' ' + clm2.patNm) {
      return 1;
    } else if (clm1.patLstNm + ' ' + clm1.patNm == clm2.patLstNm + ' ' + clm2.patNm) {
      return 0;
    } else if (clm1.patLstNm + ' ' + clm1.patNm < clm2.patLstNm + ' ' + clm2.patNm) {
      return -1;
    }
  }

  clmSortByNmDesc(clm1, clm2): number {
    if (clm1.patLstNm + ' ' + clm1.patNm < clm2.patLstNm + ' ' + clm2.patNm) {
      return 1;
    } else if (clm1.patLstNm + ' ' + clm1.patNm == clm2.patLstNm + ' ' + clm2.patNm) {
      return 0;
    } else if (clm1.patLstNm + ' ' + clm1.patNm > clm2.patLstNm + ' ' + clm2.patNm) {
      return -1;
    }
  }

  clmSortByDtAsc(clm1, clm2): number {
    if (clm1.casDt.substring(6, 8) + clm1.casDt.substring(0, 2) + clm1.casDt.substring(3, 5) > clm2.casDt.substring(6, 8) + clm2.casDt.substring(0, 2) + clm2.casDt.substring(3, 5)) {
      return 1;
    } else if (clm1.casDt.substring(6, 8) + clm1.casDt.substring(0, 2) + clm1.casDt.substring(3, 5) == clm2.casDt.substring(6, 8) + clm2.casDt.substring(0, 2) + clm2.casDt.substring(3, 5)) {
      return 0;
    } else if (clm1.casDt.substring(6, 8) + clm1.casDt.substring(0, 2) + clm1.casDt.substring(3, 5) < clm2.casDt.substring(6, 8) + clm2.casDt.substring(0, 2) + clm2.casDt.substring(3, 5)) {
      return -1;
    }
  }

  clmSortByDtDesc(clm1, clm2): number {
    if (clm1.casDt.substring(6, 8) + clm1.casDt.substring(0, 2) + clm1.casDt.substring(3, 5) < clm2.casDt.substring(6, 8) + clm2.casDt.substring(0, 2) + clm2.casDt.substring(3, 5)) {
      return 1;
    } else if (clm1.casDt.substring(6, 8) + clm1.casDt.substring(0, 2) + clm1.casDt.substring(3, 5) == clm2.casDt.substring(6, 8) + clm2.casDt.substring(0, 2) + clm2.casDt.substring(3, 5)) {
      return 0;
    } else if (clm1.casDt.substring(6, 8) + clm1.casDt.substring(0, 2) + clm1.casDt.substring(3, 5) > clm2.casDt.substring(6, 8) + clm2.casDt.substring(0, 2) + clm2.casDt.substring(3, 5)) {
      return -1;
    }
  }

  clmSortByContrAsc(clm1, clm2): number {
    if (clm1.casCont > clm2.casCont) {
      return 1;
    } else if (clm1.casCont == clm2.casCont) {
      return 0;
    } else if (clm1.casCont < clm2.casCont) {
      return -1;
    }
  }

  clmSortByContrDesc(clm1, clm2): number {
    if (clm1.casCont < clm2.casCont) {
      return 1;
    } else if (clm1.casCont == clm2.casCont) {
      return 0;
    } else if (clm1.casCont > clm2.casCont) {
      return -1;
    }
  }

  clmSortByCasAsc(clm1, clm2): number {
    if (clm1.casNo.padStart(15, '0') + clm1.casOfNo.padStart(4, '0') > clm2.casNo.padStart(15, '0') + clm2.casOfNo.padStart(4, '0')) {
      return 1;
    } else if (clm1.casNo.padStart(15, '0') + clm1.casOfNo.padStart(4, '0') == clm2.casNo.padStart(15, '0') + clm2.casOfNo.padStart(4, '0')) {
      return 0;
    } else if (clm1.casNo.padStart(15, '0') + clm1.casOfNo.padStart(4, '0') < clm2.casNo.padStart(15, '0') + clm2.casOfNo.padStart(4, '0')) {
      return -1;
    }
  }

  clmSortByCasDesc(clm1, clm2): number {
    if (clm1.casNo.padStart(15, '0') + clm1.casOfNo.padStart(4, '0') < clm2.casNo.padStart(15, '0') + clm2.casOfNo.padStart(4, '0')) {
      return 1;
    } else if (clm1.casNo.padStart(15, '0') + clm1.casOfNo.padStart(4, '0') == clm2.casNo.padStart(15, '0') + clm2.casOfNo.padStart(4, '0')) {
      return 0;
    } else if (clm1.casNo.padStart(15, '0') + clm1.casOfNo.padStart(4, '0') > clm2.casNo.padStart(15, '0') + clm2.casOfNo.padStart(4, '0')) {
      return -1;
    }
  }

  carcSortByCodeAsc(carc1, carc2): number {
    if (carc1.code > carc2.code) {
      return 1;
    } else if (carc1.code == carc2.code) {
      return 0;
    } else if (carc1.code < carc2.code) {
      return -1;
    }
  }

  displayCasSortArrow(prevDiv, div, th, sortDirSet): boolean {
    if (!(div.id).startsWith(th.id)) {  // This th not clicked
      div.hidden = true;
    } else {  // This th was clicked
      if (!sortDirSet) {
        if (prevDiv.id == div.id) {
          this.sortAsc = !this.sortAsc; // Invert sort direction
        } else {
          this.sortAsc = true;
        }
        sortDirSet = true;
      }
    }
    return sortDirSet;
  }

  sort835Claims(event): void {
    this.sortAsc = event.dir;
    this.prevSrtClass = event.prevSrtClass;
    const claims2sort = [...this._835cas]; // New independent array
    this._835cas = []; // Needed to trigger change so that cas-found pipe re-activates

    switch (event.class) {
      case 'srt835Nm':
        if (this.sortAsc) {
          claims2sort.sort(this.clmSortBy835NmAsc);
        } else {
          claims2sort.sort(this.clmSortBy835NmDesc);
        }
        break;
      case 'srt835Contr':
        if (this.sortAsc) {
          claims2sort.sort(this.clmSortBy835ContrAsc);
        } else {
          claims2sort.sort(this.clmSortBy835ContrDesc);
        }
        break;
      case 'srt835Cas':
        if (this.sortAsc) {
          claims2sort.sort(this.clmSortBy835CasAsc);
        } else {
          claims2sort.sort(this.clmSortBy835CasDesc);
        }
    }

    this._835cas = claims2sort;  // Causes re-rendering of table after sort
    this.currentPageCas = 1;
  }

  clmSortBy835NmAsc(clm1, clm2): number {
    if (clm1.casPatLstNm + ' ' + clm1.casPatFstNm > clm2.casPatLstNm + ' ' + clm2.casPatFstNm) {
      return 1;
    } else if (clm1.casPatLstNm + ' ' + clm1.casPatFstNm == clm2.casPatLstNm + ' ' + clm2.casPatFstNm) {
      return 0;
    } else if (clm1.casPatLstNm + ' ' + clm1.casPatFstNm < clm2.casPatLstNm + ' ' + clm2.casPatFstNm) {
      return -1;
    }
  }

  clmSortBy835NmDesc(clm1, clm2): number {
    if (clm1.casPatLstNm + ' ' + clm1.casPatFstNm < clm2.casPatLstNm + ' ' + clm2.casPatFstNm) {
      return 1;
    } else if (clm1.casPatLstNm + ' ' + clm1.casPatFstNm == clm2.casPatLstNm + ' ' + clm2.casPatFstNm) {
      return 0;
    } else if (clm1.casPatLstNm + ' ' + clm1.casPatFstNm > clm2.casPatLstNm + ' ' + clm2.casPatFstNm) {
      return -1;
    }
  }

  clmSortBy835ContrAsc(clm1, clm2): number {
    if (clm1.casContNo > clm2.casContNo) {
      return 1;
    } else if (clm1.casContNo == clm2.casContNo) {
      return 0;
    } else if (clm1.casContNo < clm2.casContNo) {
      return -1;
    }
  }

  clmSortBy835ContrDesc(clm1, clm2): number {
    if (clm1.casContNo < clm2.casContNo) {
      return 1;
    } else if (clm1.casContNo == clm2.casContNo) {
      return 0;
    } else if (clm1.casContNo > clm2.casContNo) {
      return -1;
    }
  }

  clmSortBy835CasAsc(clm1, clm2): number {
    if (clm1.casPsDesc == '???') {  // Accumulate at the bottom
      return 1;
    }
    if (clm2.casPsDesc == '???') {
      return -1;
    }

    if (clm1.casNo > clm2.casNo) {
      return 1;
    } else if (clm1.casNo == clm2.casNo) {
      return 0;
    } else if (clm1.casNo < clm2.casNo) {
      return -1;
    }
  }

  clmSortBy835CasDesc(clm1, clm2): number {
    if (clm1.casPsDesc == '???') {  // Accumulate at the top
      return -1;
    }
    if (clm2.casPsDesc == '???') {
      return 1;
    }

    if (clm1.casNo < clm2.casNo) {
      return 1;
    } else if (clm1.casNo == clm2.casNo) {
      return 0;
    } else if (clm1.casNo > clm2.casNo) {
      return -1;
    }
  }

  validateCkRxDt(ckRx, ckDt, c): string {
    let rowClass = c % 2 == 0 ? 'evenCksSrch' : 'oddCksSrch';
    if (ckRx && ckDt) {
      let dtCk = new Date(20 + ckDt.match(/\d{2}$/g)[0], parseInt(ckDt.match(/^\d{1,2}/g)[0]) - 1, ckDt.match(/\/\d{2}\//g)[0].replace(/\//g, ''), 0, 0, 0, 0);
      let dtRx = new Date(ckRx + ' 0:0:0.0');
      let tDt = new Date();
      return (dtCk <= dtRx && dtRx <= new Date(Date.now()) ? rowClass : "redBackgColr");
    }
    return rowClass;
  }

  ddnListSelectionClkd(value: any, ckid: string) {
    const c = this.checks.findIndex(ck => { return ck.ckgID === ckid });
    switch (value.typ) {
      case 'provNpi':
        this.checks[c].ckNPI = value.value;
        break;
      case 'insPayerID':
        this.checks[c].ckPayerID = value.value;
        break;
      default:
        alert('Unspecified `value.typ` @ ck-register.component');
        return;
    }
    this.ckPropValChange(this.checks[c]);
  }

  ddnListSelectionClkdForHeadFilter(value) {
    if (value?.typ == 'provNpi') {
      this.srchNpi = value.value;
      this.srchNpiTtip = value.name;
    } else if (value?.typ == 'insPayerID') {
      this.srchPayerID = value.value;
      this.srchPayerIDttip = value.name;
    }

    this.checks?.forEach(o => {
      if (!this.srchNpi && !this.srchPayerID) {
        o.hide = false;
      } else if (this.srchNpi && o.ckNPI == this.srchNpi && this.srchPayerID && o.ckPayerID == this.srchPayerID) {
        o.hide = false;
      } else if (this.srchNpi && o.ckNPI == this.srchNpi && !this.srchPayerID) {
        o.hide = false;
      } else if (this.srchPayerID && o.ckPayerID == this.srchPayerID && !this.srchNpi) {
        o.hide = false;
      } else {
        o.hide = true;
      }
    });

    this.calcTotals();
  }

  createCopyOfCheck(check: ICheck): void {
    this.checkCopy = JSON.parse(JSON.stringify(check)); // Deep copy of object without undefined properties
  }

  ckPropValChange(check: ICheck): void {  // Avoid http trip to server if no changes
    if (check != this.checkCopy) {
      const { // Object deconstruction into properties
        // ckgID,
        // ckDt,
        // ckgNo,
        // ckTotal,
        // ckCredited,
        // ckBalance,
        ckNPI,
        ckPayerID,
        ckPayerID835,
        ckRxDt,
        // ckFilNm,
        // ckFilDt,
        // ckChildren,
        // ckPos,
        hide } = check;

      if ((ckRxDt && this.checkCopy?.ckRxDt && ckRxDt != this.checkCopy?.ckRxDt)
        || (!ckRxDt && this.checkCopy?.ckRxDt)
        || (ckRxDt && !this.checkCopy?.ckRxDt)) {
        this.updateCkRegister(check);
      } else if ((ckNPI && this.checkCopy?.ckNPI && ckNPI != this.checkCopy?.ckNPI)
        || (!ckNPI && this.checkCopy?.ckNPI)
        || (ckNPI && !this.checkCopy?.ckNPI)) {
        this.updateCkRegister(check);
      } else if ((ckPayerID && this.checkCopy?.ckPayerID && ckPayerID != this.checkCopy?.ckPayerID)
        || (!ckPayerID && this.checkCopy?.ckPayerID)
        || (ckPayerID && !this.checkCopy?.ckPayerID)) {
        this.updateCkRegister(check);
      }
    }
  }

  updateCkRegister(check: ICheck): void {
    const regex = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{2})/gm;
    let rxDt: string;
    let match = regex.exec(check.ckRxDt);
    if (match && match[0] && match[1] && match[2] && match[3]) {
      rxDt = '20' + match[3] + match[1] + match[2]; // Gregorian form of date
    }

    console.log('%c' + 'q ' + "Exec spMB_Sio_UpdCheck "
      + "@sn = '" + this.sn
      + "', @ckgID = '" + check.ckgID
      + "', @ckgNo = '" + check.ckgNo
      + "', @ckgPayerId = '" + check.ckPayerID
      + "', @ckgNPI = '" + check.ckNPI + "'"
      + (rxDt ? ", @ckgRxDate = '" + rxDt + "'" : "")
      + (check.ckFilNm ? ", @ckgFileName = '" + this.extractFilNm(check.ckFilNm) + "'" : "")
      + (check.ckFilDt ? ", @ckgFileDate = '" + check.ckFilDt + "'" : "")
      + ";", 'color: black; background: #90EE90; font-size: 12px');

    this.waiting4Response = true;

    this._websocketService.sendChat('query', this.sn, "Exec spMB_Sio_UpdCheck "
      + "@sn = '" + this.sn
      + "', @ckgID = '" + check.ckgID
      + "', @ckgNo = '" + check.ckgNo
      + "', @ckgPayerId = '" + check.ckPayerID
      + "', @ckgNPI = '" + check.ckNPI + "'"
      + (rxDt ? ", @ckgRxDate = '" + rxDt + "'" : "")
      + (check.ckFilNm ? ", @ckgFileName = '" + this.extractFilNm(check.ckFilNm) + "'" : "")
      + (check.ckFilDt ? ", @ckgFileDate = '" + check.ckFilDt + "'" : "")
      + ";");
  }

  extractFilNm(field): string {
    return field.match(/^[\w -.]*[.]\w*/g);
  }

  jul2GregDt(dt: string): string {  // Enhance as needed for different inputs
    const rgx = /^(?<yr>\d{4})(?<mo>\d{2})(?<dy>\d{2})$/;
    const mch = rgx.exec(dt);
    console.log(mch.groups.mo + '/' + mch.groups.dy + '/' + mch.groups.yr);
    return mch.groups.mo + '/' + mch.groups.dy + '/' + mch.groups.yr;
  }

  onClick_get835file(check: ICheck, print: boolean): void { // Just need filNm, filDt
    this.checkPrint = print;
    this.createCopyOfCheck(check);
    const sp = 'spMB_Sio_Get835FileDetail';
    const filNm = this.extractFilNm(check.ckFilNm);
    const filDt = check.ckFilDt.substring(0, 8);
    this.chkgNo = check.ckgNo;
    this.chkgDt = check.ckDt;
    this.chkgBalance = check.ckBalance;
    this.adjCnt = '0';
    this.adjTotal = "0";
    this.reclCnt = "0";
    this.reclTotal = "0";
    this.pendCnt = "0";
    this.corrCnt = "0";
    // this.topCases ???

    // console.log('filNm');
    // console.log(filNm);
    if (!filNm || filNm.length == 0) {
      return;
    } else {
      this._835fil = [];
      this._835ck = [];
      this._835cas = [];
      this._835svc = [];
      this.carcNotes = [];
      this.waiting4Response = true;

      let token: string = (new Date().getTime()).toString();
      this.activeSqlProcs.push({ sp: sp, token: (new Date().getTime()).toString() });

      console.log('%c' + 'q ' + "Exec " + sp + " @sn = '" + this.sn
        + "', @filNm ='" + filNm
        + "', @filDt ='" + filDt
        + "', @print = '" + (print ? '1' : '0')
        + (this.showZeroBal ? "', @zeroBal = '1" : "")
        + "', @token = '" + token + "';", 'color: black; background: #90EE90; font-size: 12px');
      console.log(this.activeSqlProcs);

      this._websocketService.sendChat('query', this.sn, "Exec " + sp + " @sn = '" + this.sn
        + "', @filNm ='" + filNm
        + "', @filDt = '" + filDt
        + "', @print = '" + (print ? '1' : '0')
        + (this.showZeroBal ? "', @zeroBal = '1" : "")
        + "', @token = '" + token + "';"); // Gets 835 file details

      this.showZeroBal = false; // Keep it false here as a default
    }
  }

  setRptHeadParams(check: ICheck): void {
    this.ckgID = check.ckgID;
    this.chkgNo = check.ckgNo;
    this.chkgDt = check.ckDt;
    this.chkgTotal = check.ckTotal;
    this.chkgBalance = check.ckBalance;
    this.chkgNPI = check.ckNPI;
    this.chkgPayerId = check.ckPayerID;
    this.currentPageClms = 1;
  }

  reduceClaimsArrProps4search(clmsArr: any[]): void { // Mapping between ckClaims & _835cas arrays
    this.claimsPropsReduced = [];
    if (clmsArr?.length) {  // Do only if there are claims for this ck
      let isArrCkClaims: boolean = !clmsArr[0].hasOwnProperty('casTaxAmnt');  // Unique to 835 claims
      for (let i = 0; i < clmsArr.length; i++) {
        this.claimsPropsReduced.push({
          'casID': clmsArr[i].casID,
          'patLstNm': isArrCkClaims ? clmsArr[i].patLstNm : clmsArr[i].casPatLstNm,
          'patNm': isArrCkClaims ? clmsArr[i].patNm : clmsArr[i].casPatFstNm,
          'casCont': isArrCkClaims ? clmsArr[i].casCont : clmsArr[i].casContNo,
          'casNo': clmsArr[i].casNo,
          'adjusted': isArrCkClaims ? clmsArr[i].adjusted : clmsArr[i].casAdjed,
          'reclaimed': isArrCkClaims ? clmsArr[i].reclaimed : clmsArr[i].casRecled,
          'casPsDesc': isArrCkClaims ? undefined : clmsArr[i].casPsDesc,
          'indx': clmsArr[i].indx
        });
      }
    }
  }

  showSuccessToast(): void {
    this._appToastsService.updateDeadCenter(true); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
    this._appToastsService.show(this.successToast, { autohide: true, delay: 1500 });
    this.waiting4Response = false;
  }

  getCarcDescrFromReasonCode(codes: string): void {
    this._ckRegister835DetailService.getCarcDescrFromReasonCode(codes ? codes : '')
      .subscribe({
        next: data => {
          if (!data) {
            if (!this.checkPrint) {
              this.carcNotes.push({ code: 'xxx', descr: 'CODE NOT FORUND - CODIGO NO ENCONTRADO' });
            }
          } else {
            data.forEach(itm => this.carcNotes.push(itm));
          }

          if (this.checkPrint) {  // Safe to print from here since all carc codes are loaded
            this.setRptHeadParams(this.checkCopy);
            this.print835check(this.filNm, this.filDt, this.filAmnt, this.filPayerNm, this.filPayeeNm,
              this.chkgNo, this.chkgDt, this._835ck[0].ckPayeeNpi, this._835ck[0].ckPayerID, this._835cas,
              this._835svc, this._835adj, this.carcNotes, this.sn);
          }
          this.checkPrint = false;
        },
        error: (err: any) => {
          this._appToastsService.updateDeadCenter(false);
          this._appToastsService.show(err.displayMsg, { header: 'ERROR', autohide: false, error: true });
          return;
        }
      });
  }

  onKeyup_escape(event) { // Looking for Escape to exit modal - not using keyboard: true option
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.closeReclaimsModal();
    }
  }

  match835Payments2cases(): void {
    this.unMatchedClaimCnt = 0;
    let qry: string = '';
    const token: string = (new Date().getTime()).toString();

    this._835cas.forEach(cas => {
      if (!cas.casID || cas.casID == '0' || cas.casPS == '0') {
        this.unMatchedClaimCnt += 1;
        let svcCpts: string[] = [];
        let svcMods: string[] = [];
        let svcDts: string[] = [];
        let svcQtys: string[] = [];
        this._835svc.filter(svc => {
          if (svc.svcCasSeqNo == cas.casSeqNo) {
            return svc;
          }
        }).forEach(svc => {
          if (svc.svcCasSeqNo == cas.casSeqNo) {
            svcCpts.push(svc.svcProc);
            svcMods.push(svc.svcMod1 + svc.svcMod2 + svc.svcMod3 + svc.svcMod4);
            svcDts.push(svc.svcDtFrom);
            svcQtys.push(svc.svcQty);
          }
        });

        qry += "Exec spMB_Sio_Match835Payments2cases @sn = '" + this.sn
          + "', @indx ='" + cas.indx
          + "', @cpts ='" + String(svcCpts)
          + "', @mods ='" + String(svcMods)
          + "', @dts ='" + String(svcDts)
          + "', @qtys ='" + String(svcQtys)
          + "', @casNo ='" + cas.casNo.substring(0, cas.casNo.length - 4)
          + "', @casOfNo ='" + cas.casNo.substring(cas.casNo.length - 4)
          + "', @contrNo ='" + cas.casContNo
          + "', @token ='" + token
          + "';";
      }
    });

    if (qry) {
      this.activeSqlProcs.push({ sp: 'spMB_Sio_Match835Payments2cases', token: token });
      console.log('%c' + 'q ' + qry, 'color: black; background: #90EE90; font-size: 12px');
      this._websocketService.sendChat('query', this.sn, qry);
    } else {
      this.showSuccessToast();
    }
  }

  ckClaimDetailsLoaded(): void {
    this._835arrLoadCnt += 1;
    if (this._835arrLoadCnt === 5) {  // All arrays loaded _835fil _835ck _835cas _835svc _835adj
      this._835arrLoadCnt = 0;

      if (!this.checkPrint) {
        this.match835Payments2cases();
      }
    }
  }

  credit835Payments(): void {
    this._appToastsService.clear();
    this.finished = false;
    this.ckClaims = undefined;  // Array used in manual payment entry only
    this.savedPaymentsMax = this._835svc.length;
    this.prevCredited = this.previouslyCredited();
    this._835cas.forEach(cas => {
      this.procs = [];
      this._835svc.filter(svc => svc.svcCasSeqNo === cas.casSeqNo).forEach(sv => {
        if (+cas.casID) {  // Only for identified payments
          this.proc = {
            detID: '',
            detCasID: cas.casID,
            detPS: cas.casPsDesc.toLowerCase() === 'pri' ? '1' : '2',
            fromDt: sv.svcDtFrom, // m-d-y
            toDt: sv.svcDtTo,
            detPcode: sv.svcProc,
            mods: sv.svcMod1 + sv.svcMod2 + sv.svcMod3 + sv.svcMod4,
            detQty: sv.svcQty,
            diags: '',
            detPos: '',
            usual: '',
            xpect: '',
            xpectOriginal: '',
            detPayed: '',
            newPayID: '',
            icn: cas.casPayICN,
            pvdo: '',
            detDesc: '',
            detAMin: '',
            payment: sv.svcPayedAmnt,
            payCkSeqNo: sv.payCkSeqNo,
            svcPos: sv.svcPos,
            casPos: cas.casPos,
            casSeqNo: cas.casSeqNo,
            ckPos: this._835ck[0].ckPos,
            payErr: false
          };
          this.procs.push(this.proc);
        }
      });
      if (this.procs.length) {
        this.savedPaymentsCnt = 0;
        this.savePayments('835(5010)');  // Save payment for 1 claim in this._835cas at a time
      }
    });
  }

  unCreditedPaymentsCnt(): string {
    let cnt: number = 0;  // Unaplied payments count
    if (this._835svc) {
      const payd = this._835svc.filter(pay => !pay.paymentSavedLocation.match(/^\d+\-\d+\-S(1|2)/g)); // Unaplied payments
      payd.forEach(svc => {
        this._835cas.forEach(cas => {
          if (cas.casSeqNo == svc.svcCasSeqNo && +cas.casID) {  // +cas.casID => matched cases
            cnt += 1;
            // console.log('cas.casSeqNo', cas.casSeqNo);
            // console.log('%c' + 'cas.casSeqNo', 'color: red; background: yellow; font-size: 15px');
            // console.log('this._835svc', this._835svc);
            // console.log('%c' + 'this._835svc', 'color: red; background: yellow; font-size: 15px');
          }
        })
      });
    }
    this.unMatchedClaimCnt = +this.unMatchedClaims();
    this.unCredPayCnt = cnt.toString();
    return this.unCredPayCnt;
  }

  updtTotAdjCnt(event: any): void {
    const cas: I835cas = this._835cas.find(ca => ca.casID == event.adjustedCasID && ca.casPS == event.ps);
    if (cas) {
      this.adjCnt = (+this.adjCnt + (+event.adjed ? 1 : -1)).toString();
      this.adjTotal = (+this.adjTotal + +event.adjAmnt * (+event.adjed ? 1 : -1)).toString();
      cas.casBal = event.balance;
      cas.casAdjed = event.adjed;
      cas.casAdjDetID = event.adjDetID;
    }
    console.dir(event);
    console.dir(this._835cas);
  }

  unMatchedClaims(): string {
    const c: I835cas[] = this._835cas.filter(cas => !+cas.casID || !+cas.casPS);
    this.unMatchedPayCnt = 0;
    c.forEach(cas => this.unMatchedPayCnt += this.unMatchedPaysPerCas(cas))
    return c.length.toString();
  }

  unMatchedPaysPerCas(cas): number {
    return this._835svc.filter(sv => sv.svcCasSeqNo === cas.casSeqNo).length
  }

  previouslyCredited(): string {
    return this._835svc.filter(obj => obj.paymentSavedLocation != '').length.toString();
  }

  copy2ClipBoard(str: string, desc: string) {
    this._clipboard.copy(str);
    this._appToastsService.updateDeadCenter(false);
    this._appToastsService.show(str, { header: desc + (this.engLang ? ' copied ' : ' copiado'), autohide: true, warning: true });
  }

  paymentUndoable(claim): boolean {
    return this.undoPaysArr.some(pay => pay.payCasID == claim.casID && pay.ps == claim.ps);
  }

  toggleShowChildPayments(event): void {
    this.showChildPayments = false; // Make it false instead of event just to be sure
  }

  onDtpDateSelect(event, dt: string, id: any): string { // ngbDatepicker event
    // Input date dt can be in 2 or 4 digit year format, output will be 2 yr format
    // If clrCks = true then this.checks is set to []
    let vdtYy: string;
    if (dt) {
      if (dt.match(/^\d{6}$/g)) {
        dt = dt.substring(0, 2) + '/' + dt.substring(2, 4) + '/' + dt.substring(4);
      } else if (dt.match(/^\d{8}$/g)) {
        dt = dt.substring(0, 2) + '/' + dt.substring(2, 4) + '/' + dt.substring(6);
      }

      let dtMom = moment(dt, 'MM/DD/YY', true);
      if (!dtMom.isValid()) { // In case dt came in formated as MM/DD/YYYY
        dtMom = moment(dt, 'MM/DD/YYYY', true);
      }

      if (dtMom.isValid()) {
        const vdt: string = dtMom.format('MM/DD/YYYY'); // Validated date with YYYY
        vdtYy = dtMom.format('MM/DD/YY'); // To be returned
      }
      if (id) { // only when changing srchFromDt or srchToDT dates
        if ((id == 'srchFromDt' && this.srchFromDt != this.srchFromDt2)
          || (id == 'srchToDt' && this.srchToDt != this.srchToDt2)) {
          this.clearChecks();
        }
      }
    }
    return vdtYy;
  }

  setPendCnt(): void { // Cases w/o any payment credited in a given check just for display in ckRegDetModalHd
    this.pendCnt = '0';
    if (this._835cas) {
      this._835cas.forEach(ca => {
        if (ca.casPsDesc == '???') {
          this.pendCnt = (+this.pendCnt + 1).toString();
        } else {
          for (let i = 0; i < this._835svc.length; i++) {
            const svcPyd = this._835svc.some(sv => sv.svcCasSeqNo === ca.casSeqNo && sv.paymentSavedLocation.match(/^\d+\-\d+\-S(1|2)/g));
            if (svcPyd) {
              break;
            } else {
              this.pendCnt = (+this.pendCnt + 1).toString();
              break;
            }
          }
        }
      });
    } else {
      this.pendCnt = '';  // Not applicable for manual payments
    }
  }

  print835remittance(event): void {
    this.print835check(event.filNm, event.filDt, event.filAmnt, event.filPayerNm, event.filPayeeNm,
      event.chkgNo, event.chkgDt, event.ckPayeeNpi, event.ckPayerID,
      event.cas, event.svc, event.adj, event.carcNotes, event.sn);
  }

  print835check(filNm: string, filDt: string, filAmnt: string, filPayerNm: string, filPayeeNm: string,
    chkgNo: string, chkgDt: string, ckPayeeNpi: string, ckPayerID: string,
    cas: I835cas[], svc: I835svc[], adj: any[], carcNotes: any[], sn: string): void {
    if (ckPayeeNpi && ckPayerID && cas?.length > 0 && svc?.length > 0) {
      let pgLast: number = 1;  // Last page no
      let lc: number = 7; // Initial line count to include report heading
      let pc: number = 1; // Page count

      let claims: any[] = JSON.parse(JSON.stringify(cas));  // Deep independent copy
      let claims2Print: any[] = [];
      let ntesLc: number = 0;

      let ci: number = 0;  // claims index
      claims.forEach(claim => {
        claim['page'] = pc;
        pgLast = pc;
        claim['line'] = lc;

        let tSvcCnt: number = svc.filter(svc => svc.svcCasSeqNo == claim.casSeqNo).length;
        let tAdjCnt: number = 0;
        svc.forEach(svc => {
          if (svc.svcCasSeqNo == claim.casSeqNo) {
            tAdjCnt += adj.filter(adj => adj.adjCasAdjSvcSeqNo == svc.svcSeqNo).length;
          }
        });
        claim['lins'] = tSvcCnt + tAdjCnt;

        svc.forEach(svc => {
          if (svc.svcCasSeqNo == claim.casSeqNo) {
            let pgCnts: any = this.checkUpdateLineCount(pc, lc, claim['lins'], ntesLc);
            lc = pgCnts.lc;
            svc['line'] = lc;
            pc = pgCnts.pc;
            claim['page'] = pc;
            pgLast = pc;

            adj.forEach(adj => {
              if (adj.adjCasAdjSvcSeqNo == svc.svcSeqNo) {
                lc += 1;
                pgCnts = this.checkUpdateLineCount(pc, lc, claim['lins'], ntesLc);
                lc = pgCnts.lc;
                adj['line'] = lc;
                pc = pgCnts.pc;
                claim['page'] = pc;
                pgLast = pc;
              }
            });

            lc += 1;
            pgCnts = this.checkUpdateLineCount(pc, lc, claim['lins'], ntesLc);
            lc = pgCnts.lc;
            pc = pgCnts.pc;
            claim['page'] = pc;
            pgLast = pc;
          }
        });

        if (ci == claims.length - 1) {  // Is last object ?
          ntesLc = 2 * carcNotes.length + 4;
        } else {
          ci += 1;
        }

        lc += 4;  // Inclues 3 lines ovrhead per claim
        let pgCnts = this.checkUpdateLineCount(pc, lc, claim['lins'], ntesLc);
        lc = pgCnts.lc;
        pc = pgCnts.pc;
        claim['page'] = pc;
        pgLast = pc;
      });

      let gtotBilledAmnt: number = 0;
      let gtotAllowAmnt: number = 0;
      let gtotDeductAmnt: number = 0;
      let gtotCoinsAmnt: number = 0;
      let gtotPayedAmnt: number = 0;
      let gtotCarcAmnt: number = 0;

      for (let c = 0; c < claims.length; c++) {
        let totBilledAmnt: number = 0;
        let totAllowAmnt: number = 0;
        let totDeductAmnt: number = 0;
        let totCoinsAmnt: number = 0;
        let totPayedAmnt: number = 0;
        let totCarcAmnt: number = 0;

        if (c == claims.length - 1) {
          claims[c]['last'] = true; // Last claim
        }

        for (let s = 0; s < svc.length; s++) {

          if (svc[s].svcCasSeqNo == claims[c].casSeqNo) {
            if (!claims[c].hasOwnProperty('svcs')) {
              claims[c]['svcs'] = [];
            };

            let adCarc: string = undefined;
            let amnt: string = (svc[s].svcCasAdjAmnt.match(/^[-]?\d*[.]?\d*$/g)[0] ? parseFloat(svc[s].svcCasAdjAmnt).toFixed(2) : '');
            amnt = Array(8 - amnt.length).join(" ") + amnt + '  ';  // Alt-255
            let svCarc = (svc[s].svcCasAdjGrpCode + (svc[s].svcCasAdjReasonCode ? '-' : '')
              + svc[s].svcCasAdjReasonCode + Array(6).join(" ")).substring(0, 6)
              + amnt
              + (svc[s].svcCasAdjQty && svc[s].svcCasAdjQty.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcCasAdjQty).toFixed(3) : '');

            let adjs: any[] = [];
            totCarcAmnt += (svc[s].svcCasAdjAmnt && svc[s].svcCasAdjAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcCasAdjAmnt) : 0);

            for (let a = 0; a < adj.length; a++) {

              if (adj[a].adjSvcSeqNo == svc[s].svcSeqNo && adj[a].adjCasAdjSvcSeqNo == svc[s].svcSeqNo) {
                amnt = parseFloat(adj[a].adjAmount).toFixed(2);
                amnt = Array(8 - amnt.length).join(" ") + amnt + '  ';  // Alt-255
                adCarc = (adj[a].adjGrpCode + (adj[a].adjReasonCode ? '-' : '')
                  + adj[a].adjReasonCode + Array(6).join(" ")).substring(0, 6)
                  + amnt
                  + (adj[a].adjQty.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(adj[a].adjQty).toFixed(3) : '');

                if (adCarc != svCarc) {
                  adjs.push({
                    'adjCarc': ((adj[a].adjGrpCode + (adj[a].adjReasonCode ? '-' : '')
                      + adj[a].adjReasonCode + Array(6).join(" ")).substring(0, 6)
                      + amnt
                      + (adj[a].adjQty && adj[a].adjQty.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(adj[a].adjQty).toFixed(3) : '')),
                    'line': adj[a]['line']
                  });
                  totCarcAmnt += (adj[a].adjAmount && adj[a].adjAmount.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(adj[a].adjAmount) : 0);
                }

                if (!carcNotes?.some(obj => obj.code == adj[a].adjGrpCode)) {
                  if (!carcNotes) {
                    carcNotes = [{
                      'code': adj[a].adjGrpCode,
                      'descr': this._ckRegister835DetailService.carcGroupCodes.find(obj => obj.code == adj[a].adjGrpCode).descr
                    }];
                  } else {
                    carcNotes.push({
                      'code': adj[a].adjGrpCode,
                      'descr': this._ckRegister835DetailService.carcGroupCodes.find(obj => obj.code == adj[a].adjGrpCode).descr
                    });
                  }
                }
              }
            };

            claims[c]['svcs'].push({
              'svcRenderNpi': svc[s].svcRenderNpi,
              'svcDtFrom': svc[s].svcDtFrom.substring(4),
              'svcDtTo': svc[s].svcDtTo.substring(4) + svc[s].svcDtTo.substring(0, 5),
              'svcProc': (svc[s].svcProc + ' ' + svc[s].svcMod1 + ' ' + svc[s].svcMod2 + ' ' + svc[s].svcMod3 + ' ' + svc[s].svcMod4).trim(),
              'svcQty': (svc[s].svcQty.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcQty).toFixed(3) : ''),
              'svcRefLu': svc[s].svcRefLu,
              'svcBilledAmnt': (svc[s].svcBilledAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcBilledAmnt).toFixed(2) : ''),
              'svcAllowAmnt': (svc[s].svcAllowAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcAllowAmnt).toFixed(2) : ''),
              'svcDeductAmnt': (svc[s].svcDeductAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcDeductAmnt).toFixed(2) : ''),
              'svcCoinsAmnt': (svc[s].svcCoinsAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcCoinsAmnt).toFixed(2) : ''),
              'svcPayedAmnt': (svc[s].svcPayedAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcPayedAmnt).toFixed(2) : ''),
              'svcCarc': svCarc,
              'adjs': adjs,
              'line': svc[s]['line']
            });
            totBilledAmnt += (svc[s].svcBilledAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcBilledAmnt) : 0);
            totAllowAmnt += (svc[s].svcAllowAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcAllowAmnt) : 0);
            totDeductAmnt += (svc[s].svcDeductAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcDeductAmnt) : 0);
            totCoinsAmnt += (svc[s].svcCoinsAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcCoinsAmnt) : 0);
            totPayedAmnt += (svc[s].svcPayedAmnt.match(/^[-]?\d*[.]?\d*$/g) ? parseFloat(svc[s].svcPayedAmnt) : 0);
          }
        };
        gtotBilledAmnt += totBilledAmnt;
        gtotAllowAmnt += totAllowAmnt;
        gtotDeductAmnt += totDeductAmnt;
        gtotCoinsAmnt += totCoinsAmnt;
        gtotPayedAmnt += totPayedAmnt;
        gtotCarcAmnt += totCarcAmnt;

        claims[c]['casTotBilledAmnt'] = totBilledAmnt.toFixed(2);
        claims[c]['casTotAllowAmnt'] = totAllowAmnt.toFixed(2);
        claims[c]['casTotDeductAmnt'] = totDeductAmnt.toFixed(2);
        claims[c]['casTotCoinsAmnt'] = totCoinsAmnt.toFixed(2);
        claims[c]['casTotPayedAmnt'] = totPayedAmnt.toFixed(2);
        claims[c]['casCarcTot'] = totCarcAmnt.toFixed(2);

        claims2Print.push(claims[c]);
      }

      let p;
      let pClaims: any[] = [];
      claims.forEach(obj => {

        obj['head'] = {
          // "siteNm": this.siteNm,
          // "siteAd1": this.siteAd1,
          // "siteAd2": this.siteAd2,
          // "siteCt": this.siteCt,
          // "siteSt": this.siteSt,
          // "siteZp": this.siteZp,
          // "siteTl1": this.siteTl1,
          // "siteXt1": this.siteXt1,
          // "siteTl2": this.siteTl2,
          // "siteXt2": this.siteXt2,
          // "today": this._helpRtns.todaysDt(4),
          "pg": obj['page'],
          "pgNew": (p && p == obj['page'] ? false : true),
          "pgOf": pgLast,
          "sn": sn
        }
        p = obj['page'];

        obj['file'] = {
          "name": filNm,
          "date": filDt,
          "total": filAmnt,
          "payer": filPayerNm,
          "payee": filPayeeNm
        }

        obj['check'] = {
          "number": chkgNo,
          "date": chkgDt,
          "npi": ckPayeeNpi,
          "payerId": ckPayerID,
        }

        if (obj['last']) {
          obj['totals'] = {
            'gTotCarc': gtotCarcAmnt.toFixed(2)
            , 'gTotBilledAmnt': gtotBilledAmnt.toFixed(2)
            , 'gTotAllowAmnt': gtotAllowAmnt.toFixed(2)
            , 'gTotDeductAmnt': gtotDeductAmnt.toFixed(2)
            , 'gTotCoinsAmnt': gtotCoinsAmnt.toFixed(2)
            , 'gTotPayedAmnt': gtotPayedAmnt.toFixed(2)
            , 'totClaims': claims.length.toFixed(0)
          }

          let notes: any[] = [];
          carcNotes?.sort(this.carcSortByCodeAsc);
          notes = carcNotes;
          obj['carcNotes'] = notes;
        }

        pClaims.push(obj);
      });

      if (pClaims.length) {
        let body = {
          "template":
          {
            "name": "/835paymentFile/835paymentFile-html"
          }, "data": { "items": pClaims },
          "options": { "reports": { "save": true } }
        };

        this._jsRptService.postRenderJsRpt(body).subscribe({
          next: blob => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              let iframe = "<iframe width='100%' height='100%' src='" + reader.result.toString() + "'></iframe>"
              let win = window.open();
              win.document.open();
              win.document.write(iframe);
              win.document.close();
            };
            this.waiting4Response = false;
          },
          error: (err: any) => {
            this._appToastsService.updateDeadCenter(false);
            this._appToastsService.show(err.displayMsg,
              {
                header: ('Failed to render appointment list. - No se pudo presentar el listado de citas.')
                // + this._agendaService.errMsgSuggestion(this.engLang)
                , autohide: false, error: true
              });
          }
        });
      } else {
        this._appToastsService.updateDeadCenter(false); // Show toast in dead center of viewport - property sent back to app.component via @ViewChild in app.component
        this._appToastsService.show('', { header: this.engLang ? 'No payments to print.' : 'No hay pagos para imprimir.', autohide: false, warnning: true });
        this.waiting4Response = false;
      }
    }
  }

  checkUpdateLineCount(pc: number, lc: number, cl: number, ntesLc: number): any {
    let lcPp: number = 85 - 4;  // Line count per page 3 lines for pg footer 
    if (lc >= lcPp) {
      pc += 1;  // New page no
      lc = 7 + cl - 1 + ntesLc; // Re-init line count for pages 2+ taking into account excess in lc when pc changes
    }
    return { 'pc': pc, 'lc': lc };
  }

  setNewRecordNo(event: any): void {
    // To keep track of changes in rec no - office - yr if changed in CaseRecord Modal - NOT USED here
  }

  closeReclaimsModal() {  // Always exit reclaimsModal #reclaimsModalTmpl through here
    if (this.enabSaveRecl) {
      this.open2(this.reclaimsExitWoSaveModal, { backdrop: 'static', keyboard: false });  // New promise to avoid conflicts
    } else {
      if (!this.changeIcnObj) {
        this.reclaimsModalRef.dismiss('');
      } else {
        this.reclIdxSeld = this.changeIcnObj.nxtIcnIdx;
        this.reclID = this.changeIcnObj.nxtReclID;  // Causes change in reclaim component
        this.changeIcnObj = undefined;
      }
    }
  }

  setEnabSaveReclBtn(event: any) {
    this.enabSaveRecl = event;
  }

  resetSaveToggle(event: any) {
    this.saveToggle = event;
  }

  resetDeleteToggle(event: any) {
    this.deleteToggle = event;
  }

  print() {
    this.printToggle = true;
    setTimeout(() => {
      this.printToggle = false;
    }, 3000); //3 sec debouncer
  }

  printDelete(event: any) {
    this.reclID = event;
  }

  ckUnckReclaimCkBox(event: any) {
    this.reclCkBxCked = event;
  }

  toggleSpinner(event: boolean) {
    // To deal with, NG0100: Expression has changed after it was checked
    // From https://v17.angular.io/errors/NG0100
    setTimeout(() => {
      this.spinner = event;
    }, 0);
  }
}
