import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataMessageService } from '../data-message.service';
import { ISite } from '../interfaces/site';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription, take } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Json2Pipe } from '../json2.pipe';
import { CommonModule } from '@angular/common';
import { IQ837 } from '../interfaces/q837';
import { X12UtilsService } from '../x12-utils.service';
import { FormsModule } from '@angular/forms';
import { WebsocketService } from '../websocket.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalService } from '../modal.service';
import { RecordComponent } from 'src/app/record/record.component';

@Component({
  selector: 'app-q837',
  templateUrl: './q837.component.html',
  styleUrls: ['./q837.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatTooltipModule,
    Json2Pipe
  ]
})
export class Q837Component {
  @Input() qClms: IQ837[];
  @Input() currCasID: string; // To distinguish current case on screen if applicable
  @Input() changeQ837: boolean;  // A boolean just to trigger ngOnChanges(...) as needed
  @Output() setS837cnts: EventEmitter<any> = new EventEmitter();
  @Output() showSubmitterClick: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;

  sn: string;
  userID: string;
  engLang: boolean;
  sioSubscrpt: Subscription;
  spinner: boolean = false; // Turns on spinner while io is active
  s837errCnt: number = 0;
  s837ErrArr: string[];
  delm: string = '>~>';
  patID: string;
  casID: string;

  site: ISite = {
    pk: undefined,
    nm: undefined,
    ad1: undefined,
    ad2: undefined,
    ct: undefined,
    st: undefined,
    zp: undefined,
    tl1: undefined,
    xt1: undefined,
    tl2: undefined,
    xt2: undefined,
    em1: undefined,
    em2: undefined
  }

  clms: IQ837[] = [];
  dataSource: MatTableDataSource<any>;
  displydCols: string[] = [
    'chk',
    'open',
    'patLst',
    'patFst',
    'casDt',
    'casContr',
    'casNo',
    'usual',
    'xpect',
    'prov',
    'plan',
    'ps',
    'stat',
    'delete'
  ];
  tot = {
    'cked': '',
    'usual': '0',
    'xpect': '0',
    'errs': '0'
  }

  constructor(
    private _dmsg: DataMessageService,
    private _x12Service: X12UtilsService,
    private _websocketService: WebsocketService,
    private _modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this._dmsg.currentSnStr.subscribe(snStr => this.snChange(snStr)); // Subscription looking for changes in sn
    this._dmsg.currentUserIdStr.subscribe(userIdStr => this.userIdChange(userIdStr)); // Subscription looking for changes in userIdStr
    this._dmsg.currentEngLangStr.subscribe(engLangStr => this.engLangChange(engLangStr)); // Subscription looking for changes in engLang
    this._dmsg.currentSiteStr.subscribe(siteStr => this.siteChange(siteStr)); // Subscription looking for changes in siteName
    this._dmsg.currentSiteAd1Str.subscribe(siteAd1Str => this.siteAd1Change(siteAd1Str)); // Subscription looking for changes in siteAd1
    this._dmsg.currentSiteAd2Str.subscribe(siteAd2Str => this.siteAd2Change(siteAd2Str)); // Subscription looking for changes in siteAd2
    this._dmsg.currentSiteCtStr.subscribe(siteCtStr => this.siteCtChange(siteCtStr)); // Subscription looking for changes in siteCt
    this._dmsg.currentSiteStStr.subscribe(siteStStr => this.siteStChange(siteStStr)); // Subscription looking for changes in siteSt
    this._dmsg.currentSiteZpStr.subscribe(siteZpStr => this.siteZpChange(siteZpStr)); // Subscription looking for changes in siteZp
    this._dmsg.currentSiteTl1Str.subscribe(siteTl1Str => this.siteTl1Change(siteTl1Str)); // Subscription looking for changes in siteTl1
    this._dmsg.currentSiteXt1Str.subscribe(siteXt1Str => this.siteXt1Change(siteXt1Str)); // Subscription looking for changes in siteXt1
    this._dmsg.currentSiteTl2Str.subscribe(siteTl2Str => this.siteTl2Change(siteTl2Str)); // Subscription looking for changes in siteTl2
    this._dmsg.currentSiteXt2Str.subscribe(siteXt2Str => this.siteXt2Change(siteXt2Str)); // Subscription looking for changes in siteXt2

    this.sioSubscrpt = this._websocketService.getMessages().subscribe((dataSet) => { // Sets listenning events

      console.log('%c' + 'dataSet Q837Component', 'color: green; background: yellow; font-size: 14px');
      console.log(dataSet[0]?.sqlProcNm, dataSet);

      if (dataSet[0]?.sqlProcNm === 'spMB_Sio_Q837ChkUnchk') {
        let tcked: number = 0;
        let terrs: number = 0;
        for (let i = 0; i < this.qClms.length; i++) {
          if (this.qClms[i].pKey === dataSet[0].pKey) {
            this.qClms[i].chk = dataSet[0].chk;
          }
          if (+this.qClms[i].chk) { // Updating total cked
            tcked += 1;
          }
          if (this.qClms[i].sErrs) {
            terrs += 1;
          }
        }
        this.tot.cked = tcked.toString();
        this.tot.errs = terrs.toString();
        this.setS837cnts.emit({ errCnt: this.tot.errs, ckedCnt: this.tot.cked }); // Shows total errors in record component
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.qClms || changes.changeQ837) {
      console.log('changes.qClms', this.qClms);
      let arr: IQ837[] = this.qClms;
      const regex = new RegExp(this.delm + '$', "g");
      let tusual: number = 0;
      let txpect: number = 0;
      let tcked: number = 0;
      let terrs: number = 0;
      arr.forEach(e => {  // Prepare errors for display & totals
        e['errArr'] = e.sErrs.replace(regex, '').split(this.delm);
        if (e.sErrs) {
          terrs += 1;
        }
        tusual += +e.usual;
        txpect += +e.xpect;
        tcked += +e.chk;
      });
      this.tot.usual = tusual.toFixed(2);
      this.tot.xpect = txpect.toFixed(2);
      this.tot.cked = tcked.toString();
      this.tot.errs = terrs.toString();

      this.dataSource = new MatTableDataSource(arr);
      this.dataSource.sort = this.sort; // Always follow this.dataSource = ... with this to maintain header sort arrow synced

      this.setS837cnts.emit({ errCnt: this.tot.errs, ckedCnt: this.tot.cked }); // Shows total errors in record component
    }
  }

  ngOnDestroy() {
    this.qClms.forEach(c => c.expandCol = '');
  }

  snChange(snStr: string) { // for when sn changes
    this.sn = snStr;
  }

  userIdChange(userIdStr) {
    this.userID = userIdStr;
  }

  engLangChange(engLangStr: string) { // for when language changes
    engLangStr === 'true' ? this.engLang = true : this.engLang = false;
  }

  siteChange(siteStr: string) { // for when site changes
    this.site.nm = siteStr;
  }

  siteAd1Change(siteAd1Str: string) { // for when site changes
    this.site.ad1 = siteAd1Str;
  }

  siteAd2Change(siteAd2Str: string) { // for when site changes
    this.site.ad2 = siteAd2Str;
  }

  siteCtChange(siteCtStr: string) { // for when site changes
    this.site.ct = siteCtStr;
  }

  siteStChange(siteStStr: string) { // for when site changes
    this.site.st = siteStStr;
  }

  siteZpChange(siteZpStr: string) { // for when site changes
    this.site.zp = siteZpStr;
  }

  siteTl1Change(siteTl1Str: string) { // for when site changes
    this.site.tl1 = siteTl1Str;
  }

  siteXt1Change(siteXt1Str: string) { // for when site changes
    this.site.xt1 = siteXt1Str;
  }

  siteTl2Change(siteTl2Str: string) { // for when site changes
    this.site.tl2 = siteTl2Str;
  }

  siteXt2Change(siteXt2Str: string) { // for when site changes
    this.site.xt2 = siteXt2Str;
  }

  siteEm1Change(siteEm1Str: string) { // for when site changes
    this.site.em1 = siteEm1Str;
  }

  sortea(sort) {

  }

  onClick_expandErrs(clm: IQ837) {
    if (clm.sErrs) {
      if (!clm.expandCol) {
        clm.expandCol = 'true';
      } else {
        clm.expandCol = '';
      }
    } else if (clm.s837) {
      if (!clm.expandCol) {
        clm.expandCol = 'true';
      } else {
        clm.expandCol = '';
      }
    }
  }

  onClick_delete(clm) {
    const prompt = this.engLang ? 'Delete claim from queue ?' : '¿ Eliminar caso de la fila ?';
    const title = this.engLang ? 'Confirm' : 'Confirme';
    const btn_primary = true;
    const btn_default = true;
    let ok: string = this.engLang ? 'Yes' : 'Sí';
    let cancel = 'No';

    this._modalService.confirm(prompt, title, btn_primary, btn_default, ok, cancel)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          this._x12Service.delete837(this.sn, clm.ps, clm.casID);
        } else {
          // Nada
        }
      });
  }

  onChange_chk(event, clm) {
    if (clm.sErrs === '') {
      this._x12Service.chkUnchk837(this.sn, clm.pKey);
    } else {
      event.source.checked = !event.checked;  // Revert chkbox state
    }
  }

  onClick_showSubmitter() {
    this.showSubmitterClick.emit(true);
  }

  open(content, opts: any, modalNm: string) {
    this._modalService.open(content, opts)
      .pipe(
        take(1) // take() manages unsubscription for us
      ).subscribe(response => {
        if (response) {
          console.log(response);
          return true;
        } else {
          return false;
        }
      });
  }

}
