import { Injectable } from '@angular/core';
import { I837clm } from './interfaces/837clm';
import { ICase } from './interfaces/case';
import * as moment from 'moment';
import { ILocalFormConfig } from './interfaces/localFormConfig';
import { ILocalInsurances } from './interfaces/localInsurances';
import { ILocalProviders } from './interfaces/localProviders';
import { ILocalFacilities } from './interfaces/localFacilities';
import { WebsocketService } from './websocket.service';
import { IPatients } from './interfaces/patients';
import { HelperRtnsComponent } from './helper-rtns.component';
import { RecordService } from '../record/record.service';
import { IProc } from './interfaces/proc';
import { ILocalProductors } from './interfaces/localProductors';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ILocalSubmitters } from './interfaces/submitter';

@Injectable({
  providedIn: 'root'
})
export class X12UtilsService {
  sn: string;
  proc: string;
  postHeaders: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  endOfLine: string = "~";
  n: string = '\n'; // New line
  emk: string = '_____';  // Error Marker
  delm: string = '>~>';
  icn: string;  // PAYER CLAIM CONTROL NUMBER (ICN)
  claimPos: string; // CLM05-1
  insNm: string; // insurance name

  s270: string;  // Holds one patients eligibility request

  errors: any[] = [];

  constructor(
    private _ws: WebsocketService,
    private _help: HelperRtnsComponent,
    private _recordService: RecordService,
    private _http: HttpClient,
  ) { }

  x837Pro(sn: string, sbr01: string, uID: string, cas: ICase, pat: IPatients, fc1: ILocalFormConfig, fc2: ILocalFormConfig, prov: ILocalProviders,
    ins1: ILocalInsurances, ins2: ILocalInsurances, fac: ILocalFacilities, prod: ILocalProductors, engLang: boolean): void {
    this.errors = [];
    const s837 = this.sbrSeg(sbr01, cas, ins1, ins2)
      + this.insuredNM1(sbr01, cas, pat, true, engLang)
      + this.payerNm(sbr01, ins1, ins2)
      + this.claimInfo(sbr01, cas)
      + this.admitDt(sbr01, cas)
      + this.dischDt(cas)
      + this.patDedAmntPd(sbr01, cas)
      + this.mammoCertNo(sbr01, fc1, fc2)
      + this.referalNo(sbr01, cas)
      + this.priorAuth(cas)
      + this.icn4correction()
      + this.cliaNo(sbr01, fc1, fc2, prov)
      + this.icd10Codes(cas)
      + this.referringMD(sbr01, cas)
      + this.renderingProv(sbr01, cas, prov, fc1, fc2, ins1, ins2, prod)
      + this.facility(sbr01, cas, fac)
      + this.otherSbrSeg(sbr01, cas, ins1, ins2)
      + this.serviceLinesPro(sbr01, cas)

    this.save2q837(sn, s837, uID, cas.casID, pat.patID, sbr01, cas.casDat);
    console.log('x837Pro', s837);
    console.log('errors', this.errors);
  }

  // ISA*00*          *00*          *30*584384448      *30*660610220      *240923*2105*^*00501*000000026*1*P*:~
  // GS*HS*660610220*660610220*20240923*2105*1*X*005010X279A1~
  // ST*270*000000026*005010X279A1~
  // BHT*0022*13*100000000*20240923*2105~
  // HL*1**20*1~
  // NM1*PR*2*MEDICARE*****PI*00973~
  // HL*2*1*21*1~
  // NM1*1P*1*SOBRINO CATONI*JOSE****XX*1174602064~
  // HL*3*2*22*0~
  // TRN*1*000000000000017372*1174602064~
  // NM1*IL*1*AYUSO BENITEZ*ROSALIA****MI*7U63G89ER78~
  // DMG*D8*19380118~
  // DTP*291*D8*20240923~
  // EQ*30~
  // SE*13*000000026~
  // GE*1*1~
  // IEA*1*000000026~
  x270Eligib(ps: string, prv: ILocalProviders, ins: ILocalInsurances, pat: IPatients, cas: ICase, engLang: boolean) {
    const tdt = new Date();
    const st02 = moment(tdt).format('YYMMDDHHmmss');  // ST02: A unique transaction set control number, which matches the SE02 (the corresponding segment at the end of the transaction).
    let cdt = moment(cas.casDat, 'MM/DD/YYYY').format('YYYYMMDD');
    const dt = tdt.getFullYear().toString() + (1 + tdt.getMonth()).toString().padStart(2, '0') + tdt.getDate().toString().padStart(2, '0');
    const tm = tdt.getHours().toString().padStart(2, '0') + tdt.getMinutes().toString().padStart(2, '0');

    this.s270 = 'ST*270*' + st02 + '*005010X279A1~'
      + 'BHT*0022*13*00000000*' + dt + '*' + tm + this.endOfLine
      + 'HL*1**20*1' + this.endOfLine
      + this.payerNm(ps, ins, null)
      + 'HL*2*1*21*1' + this.endOfLine
      + this.provNm(prv, '1P')
      + 'HL*3*2*22*0' + this.endOfLine
      + 'TRN*1*' + pat.patID.padStart(18, '0') + '*' + prv.npi + this.endOfLine
      + this.insuredNM1(ps, cas, pat, false, engLang)
      + 'DTP*291*D8*' + cdt + this.endOfLine
      + 'EQ*30' + this.endOfLine
      + 'SE*__*' + st02 + this.endOfLine;

    const cnt = this.s270.match(/~/gm).length;
    this.s270 = this.s270.replace(/__/gm, cnt.toString());
    this.s270 = this.s270.replace(/\n/gm, '');
  }

  sbrSeg(sbr01: string, cas: ICase, ins1: ILocalInsurances, ins2: ILocalInsurances) {
    const grp: string = (sbr01 === 'P' ? cas.casGrp1 : cas.casGrp2);
    const cfi: string = (sbr01 === 'P' ? (ins1.x837clmFilInd ? ins1.x837clmFilInd : this.emk) : (ins2.x837clmFilInd ? ins2.x837clmFilInd : this.emk));
    const ID: string = (sbr01 === 'P' ? cas.casI1ID : cas.casI2ID);

    if (!cfi) {
      this.errors.push('Claim Filing Indicator Code plan (ID local = ' + ID + ') (Loop 2000B)')
    }
    return this.n + 'SBR*' + sbr01 + '*18*' + grp + '******' + cfi + this.endOfLine;
  }

  insuredNM1(ps: string, cas: ICase, pat: IPatients, addr: boolean, engLang: boolean): string { // Decides if using plan ID card name from cas to submit 
    let p: {
      last: string,
      first: string,
      mid: string,
      contr: string
    } = { last: '', first: '', mid: '', contr: '' };
    if (ps === 'P' || ps === '1') {
      if (cas.casILastIns1.trim()) {
        p.last = cas.casILastIns1.substring(0, 60);
        p.first = cas.casIFirstIns1.substring(0, 35);
        p.mid = cas.casIMidIns1.substring(0, 25);
      }
      p.contr = cas.casCont1;
    } else {
      if (cas.casILastIns2.trim()) {
        p.last = cas.casILastIns2.substring(0, 60);
        p.first = cas.casIFirstIns2.substring(0, 35);
        p.mid = cas.casIMidIns1.substring(0, 25);
      }
      p.contr = cas.casCont2;
    }
    if (!p.last) { // Not using cas insurance ID card names
      p.last = pat.lastNm;
      p.first = pat.firstNm;
      p.mid = pat.midInit;
    }

    if (!p.last) {
      this.errors.push("Insured's last name (Loop 2010BA)");
      p.last = this.emk;
    }
    if (!p.first) {
      this.errors.push("Insured's name (Loop 2010BA)");
      p.first = this.emk;
    }
    if (!p.contr) {
      this.errors.push("Plan contract no. (Loop 2010BA)");
      p.contr = this.emk;
    }
    if (!pat.add1) {
      this.errors.push("Patient's address 1 (Loop 2010BA)");
      pat.add1 = this.emk;
    }
    if (!pat.city) {
      this.errors.push("Patient's city (Loop 2010BA)");
      pat.city = this.emk;
    }
    if (pat.st.length != 2) {
      this.errors.push("Patient's address state (Loop 2010BA)");
      pat.st = this.emk;
    }
    if (pat.zip.length != 5 && pat.zip.length != 9) {
      this.errors.push("Patient's zip code (Loop 2010BA)");
      pat.zip = this.emk;
    }
    if (!moment(pat.dob, 'MM/DD/YYYY').isValid) {
      this.errors.push('Nacimiento paciente (Loop 2010CA)');
      pat.dob = this.emk;
    }
    if (!pat.sex.match(/^[MF]$/g)) {
      this.errors.push('Zip code paciente (Loop 2010BA)');
      pat.sex = this.emk;
    }

    return this.n + 'NM1*IL*1*' + p.last + '*' + p.first + '*' + p.mid + '***MI*' + p.contr + this.endOfLine
      + (addr ? this.n + 'N3*' + pat.add1 + (pat.add2 ? '*' + pat.add2 : '') + this.endOfLine : '')
      + (addr ? this.n + 'N4*' + pat.city + '*' + pat.st + '*' + pat.zip + this.endOfLine : '')
      + this.n + 'DMG*D8*' + this.yyyyMMdd(pat.dob, 'MM/DD/YYYY') + '*' + pat.sex + this.endOfLine;
  }

  yyyyMMdd(dt: string, fmt: string): string {
    return moment(dt, fmt).format('YYYYMMDD');
  }

  payerNm(ps: string, ins1: ILocalInsurances, ins2: ILocalInsurances): string { // ps = 'P' for claims, or '1' for eligib
    let insNm: string;
    let insPayID: string;
    if (ps === 'P' || ps === '1') {
      insNm = ins1.name;
      insPayID = ins1.payerId;
    } else {
      insNm = ins2.name;
      insPayID = ins2.payerId;
    }
    if (!insNm) {
      this.errors.push('Plan/payer name (Loop 2010BB)');
      insNm = this.emk;
    }
    if (!insPayID) {
      this.errors.push('Plan/payer payer ID (Loop 2010BB)');
      insPayID = this.emk;
    }
    return this.n + 'NM1*PR*2*' + insNm + '*****PI*' + insPayID + this.endOfLine;
  }

  claimInfo(ps: string, cas: ICase): string {
    let totUsual: number = 0;
    let freq: string = '1';  // Claim frequency - default to original claim submission

    if (ps === 'P') {
      cas.procs1.forEach(p => { totUsual += +p.usual * +p.qty });
      if (cas.procs1?.length) {
        this.claimPos = cas.procs1[0].pos;
      }
      if (cas.pays?.length) {
        this.icn = cas.pays.find(p => p.payPS === 'S1')?.payApCode;  // Should be the last icn received
      }
    } else {
      totUsual = 0;
      cas.procs2.forEach(p => { totUsual += +p.usual * +p.qty });
      if (cas.procs2?.length) {
        this.claimPos = cas.procs2[0].pos;
      }
      if (cas.pays?.length) {
        this.icn = cas.pays.find(p => p.payPS === 'S2').payApCode;  // Should be the last icn received
      }
    }
    if (this.icn) {  // If there is a payment then claim must be a correction, otherwise freq defaults to '1' (original claim)
      freq = '7';
    }
    if (!this.claimPos) {
      this.errors.push('Claim place of service CLM05-1 (Loop 2300)');
      this.claimPos = this.emk;
    }
    return this.n + "CLM*" + cas.casNo + cas.casOfNo + "*" + totUsual.toFixed(2) + "***" + this.claimPos + ":B:" + freq + "*Y*A*Y*Y*P" + this.endOfLine;
  }

  admitDt(ps: string, cas: ICase): string {
    let momDt = moment(cas.casAdmDt, 'MM/DD/YYYY');
    if (momDt.isValid()) {
      if ((ps === 'P' && cas.procs1.some(p => p.pos === '21' && moment(p.fromDt).isBefore(momDt))) || (ps === 'S' && cas.procs2.some(p => p.pos === '21' && moment(p.fromDt).isBefore(momDt)))) {
        this.errors.push('Admitted date > date FROM (Loop 2300)');
      }
      return this.n + 'DTP*435*D8*' + momDt.format('YYYYMMDD') + this.endOfLine;
    } else {
      if ((ps === 'P' && cas.procs1.some(p => p.pos === '21')) || (ps === 'S' && cas.procs2.some(p => p.pos === '21'))) {
        this.errors.push('Admitted date invalid (Loop 2300)');
        return this.n + 'DTP*435*D8*' + this.emk + this.endOfLine;  // Will trigger an error
      }
    }
    return '';
  }

  dischDt(cas: ICase): string {
    if (cas.casDiscDt) {
      let momDt = moment(cas.casDiscDt, 'MM/DD/YYYY');
      if (moment(cas.casAdmDt).isValid()) {
        if (moment(cas.casAdmDt).isSameOrBefore(momDt)) {
          return this.n + 'DTP*096*D8*' + momDt.format('YYYYMMDD') + this.endOfLine;
        } else {
          this.errors.push('Admitted date > discharge date (Loop 2300)');
          return this.n + 'DTP*096*D8*' + this.emk + this.endOfLine;
        }
      }
    }
    return '';
  }

  patDedAmntPd(ps: string, cas: ICase): string {
    const dedPS = (ps === 'P' ? 'D1' : 'D2');
    let totDed: number = 0;
    cas.pays.forEach(p => {
      if (p.payPS === dedPS) {
        totDed += +p.payAmnt;
      }
    });
    if (+totDed > cas.casChgP) {  // Guard against over reporting pvdo amount paid
      totDed = +cas.casChgP;
    }
    if (totDed) {
      return this.n + 'AMT*F5*' + totDed.toFixed(2) + this.endOfLine;
    }
    return '';
  }

  mammoCertNo(ps: string, fc1: ILocalFormConfig, fc2: ILocalFormConfig): string {
    if (ps === 'P') {
      if (fc1?.mamoCert) {
        return this.n + 'REF*EW*' + fc1.mamoCert + this.endOfLine;
      }
    } else {
      if (fc2?.mamoCert) {
        return this.n + 'REF*EW*' + fc2.mamoCert + this.endOfLine
      }
    }
    return '';
  }

  referalNo(ps: string, cas: ICase): string {
    let ref: string;
    let req: boolean = this.referalReq(ps, cas);
    if (req || cas.casRefNo) {
      ref = cas.casRefNo ? cas.casRefNo : this.emk;
      if (ref && ref === this.emk) {
        this.errors.push('Referral no.');
      }
      return this.n + 'REF*9F*' + cas.casRefNo + this.endOfLine;
    } else {
      return '';
    }
  }

  referalReq(ps: string, cas: ICase): boolean {
    let procs: IProc[];
    if (ps === 'P') {
      procs = cas.procs1;
    } else {
      procs = cas.procs2;
    }
    for (let i = 0; i < procs.length; i++) {
      return this._recordService.localPcodes.some(p => p.ref === 'True' && cas.procs1[i].code === p.code && cas.casProvID === p.provId && cas.casI1ID === p.insId);
    }
  }

  priorAuth(cas: ICase): string {
    if (cas.casAuthNo) {
      return this.n + 'REF*G1*' + cas.casAuthNo + this.endOfLine
    }
    return '';
  }

  icn4correction(): string { // PAYER CLAIM CONTROL NUMBER (ICN)
    if (this.icn) {
      return this.n + 'REF*F8*' + this.icn + this.endOfLine
    }
    return '';
  }

  cliaNo(ps: string, fc1: ILocalFormConfig, fc2: ILocalFormConfig, prov: ILocalProviders): string {  // Clinical Laboratory Improvement Amendment (CLIA) Number
    if (+prov.lab) {
      let clia: string;
      if (ps === 'P') {
        if (fc1?.clia) {
          clia = fc1.clia ? fc1.clia : this.emk;
        }
      } else {
        if (fc2?.clia) {
          clia = fc2.clia ? fc2.clia : this.emk;
        }
      }
      if (clia === this.emk) {
        this.errors.push('Clinical Laboratory Improvement Amendment (CLIA) Number');
      }
      return this.n + 'REF*X4*' + clia + this.endOfLine;
    }
    return '';
  }

  icd10Codes(cas: ICase): string {
    let dx: string = '';
    cas.dxs.forEach(d => {
      if (!dx) {
        dx = 'HI*ABK:' + d.code.replace(/[^A-Z0-9]*/g, '');
      } else {
        dx += '*ABF:' + d.code.replace(/[^A-Z0-9]*/g, '');
      }
    });
    if (!dx) {
      this.errors.push('Missing ICD10 codes');
      return this.n + this.emk + this.endOfLine;
    }
    return this.n + dx + this.endOfLine;
  }

  referringMD(ps: string, cas: ICase): string {
    let reqRef: boolean = this.referalReq(ps, cas);
    if (reqRef || +cas.casRefID) {
      let ref: string;
      let npi: string = cas.casRefNPI ? cas.casRefNPI : this.emk;
      const last = cas.casRefLastNm ? cas.casRefLastNm : this.emk;
      const first = cas.casRefFirstNm;
      if (first.length) {
        ref = 'NM1*DN*1*' + last + '*' + first  // Person
      } else {
        ref = 'NM1*DN*2*' + last + '**' // Non person
      }
      if (last === this.emk || npi === this.emk) {
        this.errors.push('Name/NPI referring MD (Loop 2310A)');
      }
      return this.n + ref + '****XX*' + npi + this.endOfLine;
    }
    return '';
  }

  renderingProv(ps: string, cas: ICase, prov: ILocalProviders, fc1: ILocalFormConfig, fc2: ILocalFormConfig, ins1: ILocalInsurances, ins2: ILocalInsurances, prod: ILocalProductors): string {
    let omitNm182: boolean = false; // To omit segment NM1*82...
    let usePrvNm185: boolean = false;
    let npi: string = prov.npi; // Default provider NPI
    let nm182: string = this.n;
    if (ps === 'P') {
      omitNm182 = +fc1?.omitNm182 ? true : false;
      usePrvNm185 = +ins1.x837usePrvAsRenderAt2310B ? true : false;
      npi = fc1?.altNpi ? fc1.altNpi : npi;
    } else {
      omitNm182 = +fc2?.omitNm182 ? true : false;
      usePrvNm185 = +ins2.x837usePrvAsRenderAt2310B ? true : false;
      npi = fc2?.altNpi ? fc2.altNpi : npi;
    }
    let last: string;
    if (prov.provName.includes(',')) {  // Person
      last = prov.provName.match(/^[\w\s]*,/g)[0].replace(',', '');
    } else {
      last = prov.provName;
    }

    if (!omitNm182 && !+prov.lab) {
      let ini: string;
      if (usePrvNm185) {
        nm182 += 'NM1*82*1*' + prov.provName.replace(/, /g, '*') + '*' + prov.provName.match(/ \w*$/g).toString();
      } else {
        if (!prod || !+prov.provIsCo || !cas.procs1.some(p => +p.prod.replace(/\D*/g, '') > 0)) {
          ini = prov.provName.match(/ \w$/g) ? prov.provName.match(/ \w$/g).toString() : '';
          nm182 += 'NM1*82*1*' + prov.provName.replace(/, /g, '*') + '*' + ini;
        } else {
          ini = prod.prodName.match(/ \w$/g) ? prod.prodName.match(/ \w$/g).toString() : '';
          nm182 += 'NM1*82*1*' + prod.prodName.replace(/, /g, '*') + '*' + ini;
        }
        nm182 += '***XX*' + (npi ? npi : this.emk) + this.endOfLine
          + this.n + 'PRV*PE*PXC*' + prov.provTaxonomy + this.endOfLine
      }

      if (!last) {
        this.errors.push('Claim Rendering Provider Name (Loop 2310B)');
      }
      if (!npi) {
        this.errors.push('Claim Rendering Provider Name NPI (Loop 2310B)');
      }
      return nm182;
    } else {
      return '';
    }
  }

  billingProvider(prov: ILocalProviders): string {
    const c: number = prov.provName.indexOf(',') === -1 ? prov.provName.length : prov.provName.indexOf(',');
    const lname = prov.provName.substring(0, c);
    const fname = prov.provName.substring(c + 1).trim();
    const nm185 = this.n + 'NM1*85*' + (+prov.provIsCo ? '2' : '1') + '*' + lname + '*' + fname + '****XX*' + prov.npi + this.endOfLine
      + this.n + 'N3*' + prov.provAd1 + (prov.provAd2 ? '*' + prov.provAd2 : '') + this.endOfLine
      + this.n + 'N4*' + prov.provCity + '*' + prov.provSt + '*' + prov.provZip.replace(/\D/g, '') + this.endOfLine
      + this.n + 'REF*' + (+prov.ssn ? 'SY' : 'EI') + '*' + prov.taxId.replace(/\D/g, '') + this.endOfLine;

    if (prov.poAd1) {
      return nm185 + this.n + 'NM1*87*' + (c ? '2' : '1') + this.endOfLine
        + this.n + 'N3*' + prov.poAd1 + (prov.poAd2 ? '*' + prov.poAd2 : '') + this.endOfLine
        + this.n + 'N4*' + prov.poCity + '*' + prov.poSt + '*' + prov.poZip + this.endOfLine
    }
    return nm185;
  }

  facility(ps: string, cas: ICase, fac: ILocalFacilities): string {
    let reqFac: boolean = false;
    if (ps === 'P') {
      if (cas.procs1.some(p => p.fac)) {
        reqFac = true;
      }
    } else {
      if (cas.procs2.some(p => p.fac)) {
        reqFac = true;
      }
    }
    if (reqFac || +cas.casFacID) {
      if (!fac.facName) {
        this.errors.push('Name hospital/facility (Loop 2010BB)');
        fac.facName = this.emk;
      }
      if (!fac.facNpi) {
        this.errors.push('NPI hospital/facility (Loop 2010BB)');
        fac.facNpi = this.emk;
      }
      if (!fac.facAd1) {
        this.errors.push('Address 1 hospital/facility (Loop 2010BB)');
        fac.facAd1 = this.emk;
      }
      if (!fac.facCity) {
        this.errors.push('City hospital/facility (Loop 2010BB)');
        fac.facCity = this.emk;
      }
      if (fac.facZip.length !== 9) {
        this.errors.push('Zip code hospital/facility (Loop 2010BB)');
        fac.facZip = this.emk;
      }
      return this.n + 'NM1*77*2*' + fac.facName + '*****XX*' + fac.facNpi + this.endOfLine
        + this.n + 'N3*' + fac.facAd1 + (fac.facAd2 ? '*' + fac.facAd2 : '') + this.endOfLine
        + this.n + 'N4*' + fac.facCity + '*' + fac.facSt + '*' + fac.facZip + this.endOfLine
    }
    return '';
  }

  otherSbrSeg(ps: string, cas: ICase, ins1: ILocalInsurances, ins2: ILocalInsurances): string {
    if (!cas.casI2ID && (!cas.casPrivacy || cas.casPrivacy == 'False')) {
      let cfi: string;  // Claim Filling Indicator
      let grp: string;
      let oID: string;  // Other ins local ID
      let oInm: string; // Other ins name
      let oPid: string; // Other ins payer ID
      let priSec: string = (ps === 'P' ? 'secondary' : 'primary');
      let sbr: string = this.n;
      if (ps === 'P' && +cas.casI2ID) { // Submitting primary with a secondary
        cfi = ins2.x837clmFilInd ? ins2.x837clmFilInd : this.emk;
        grp = cas.casGrp2;
        oID = cas.casI2ID;  // Use in case of error
        oInm = ins2.name;
        oPid = ins2.payerId;
        sbr += 'SBR*S*18*' + grp + '******' + cfi + this.endOfLine;
      } else if (ps === 'S' && +cas.casI1ID) {  // Submitting secondary with a primary & payment
        cfi = ins1.x837clmFilInd ? ins1.x837clmFilInd : this.emk;
        grp = cas.casGrp1;
        oID = cas.casI1ID;  // Use in case of error
        oInm = ins2.name;
        oPid = ins2.payerId;
        sbr += 'SBR*P*18*' + grp + '******' + cfi + this.endOfLine;
        sbr += this.n + 'AMT*D*' + cas.casPay1 + this.endOfLine;
      }
      sbr += this.n + 'OI***N*P**Y' + this.endOfLine
        + this.n + 'NM1*PR*2*' + oInm + '*****PI*' + oPid + this.endOfLine

      if (cfi === this.emk) {
        this.errors.push('Claim Filing Indicator Code other (' + priSec + ') insurance (ID local = ' + oID + ') (Loop 2000B)')
      }
      if (!cfi) {
        this.errors.push('Claim Filing Indicator Code other (' + priSec + ') insurance (ID local = ' + oID + ') (Loop 2000B)')
      }
      if (!oPid) {
        this.errors.push('Payer ID other (' + priSec + ') insurance (ID local = ' + oID + ') (Loop 2000B)')
      }
      return sbr;
    }
    return '';
  }

  serviceLinesPro(ps: string, cas: ICase): string {
    let sv: string = '';
    let cnt: number = 1;
    let casPCodes: IProc[];
    let insID: string;
    if (ps === 'P') {
      casPCodes = cas.procs1;
      insID = cas.casI1ID;
    } else {
      casPCodes = cas.procs2;
      insID = cas.casI2ID;
    }

    casPCodes.forEach(p => {
      if (p.code && p.code !== 'AJUSTE' && !p.desc.startsWith('AJUSTE')) {
        sv += this.n + 'LX*' + cnt.toString() + this.endOfLine + this.n + 'SV1*HC:';
        let noDesc: boolean = true; // Default
        let momFrom = moment(p.fromDt, 'MM/DD/YYYY');
        let dtFrom: string = '';
        if (momFrom.isValid) {
          dtFrom = momFrom.format('YYYYMMDD');
        }
        let momTo = moment(p.toDt, 'MM/DD/YYYY');
        let dtTo: string = '';
        if (momTo.isValid) {
          dtTo = momTo.format('YYYYMMDD');
        }

        let modCnt: number = 0;

        sv += p.code;
        if (p.mod1) {
          sv += ':' + p.mod1;
          modCnt++;
        }
        if (p.mod2) {
          sv += ':' + p.mod2;
          modCnt++;
        }
        if (p.mod3) {
          sv += ':' + p.mod3;
          modCnt++;
        }
        if (p.mod4) {
          sv += ':' + p.mod4;
          modCnt++;
        }

        noDesc = !this._recordService.localPcodes.some(pc => pc.code === p.code && pc.insId === insID && pc.provId === cas.casProvID && !+pc.noDesc);
        if (!noDesc) {
          switch (modCnt) {
            case 0:
              sv += ':::::';
              break;
            case 1:
              sv += '::::';
              break;
            case 2:
              sv += ':::';
              break;
            case 3:
              sv += '::';
              break;
            default:
              break;
          }
          if (!p.desc) {
            this.errors.push('Procedure description required (Loop 2400)');
            p.desc = this.emk;
          }
          sv += p.desc;
        }

        let amnt: string;
        if (+p.usual * +p.qty < +p.xpect * +p.qty) {
          amnt = (+p.xpect * +p.qty).toFixed(2);
        } else {
          amnt = (+p.usual * +p.qty).toFixed(2);
        }
        if (+amnt <= 0) {
          this.errors.push('Billed amount for ' + p.code + ' = 0.00 (Loop 2400)');
          amnt = this.emk;
        }

        sv += '*' + (+p.usual * +p.qty).toFixed(2) + '*UN*' + p.qty + '*';
        if (this.claimPos !== p.pos) {
          sv += p.pos;
        }
        sv += '**' + p.dx + this.endOfLine
          + this.n + 'DTP*472*RD8*' + dtFrom + '-';
        if (!dtTo) {
          sv += dtFrom;
        } else {
          if (momFrom.isAfter(momTo)) {
            this.errors.push('Service date From > To (Loop 2400)');
            dtTo = this.emk;
          }
          sv += dtTo + this.endOfLine;
        }
        sv += this.n + 'REF*6R*' + p.detID + this.endOfLine;  // Line Item Control Number

        if (ps === 'S' && !+cas.casPrivacy) {
          let primPayAmnt: string;
          let acc: number = 0;  // Accumulate a total
          let payDt: string;
          cas.pays.forEach(py => {
            if (+py.payDetID === +p.detID) {
              acc += +py.payAmnt;  // Sum of payments if more than 1 for this procedure
              primPayAmnt = acc.toFixed(2);
            }

            // Don't kno if next block will be needed
            // let momPayDt = moment(py.payDt, 'MM/DD/YYYY');
            // if (moment(payDt, 'MM/DD/YYYY').isValid) {
            //   if (momPayDt.isSameOrBefore(moment(py.payDt, 'MM/DD/YYYY'))) {
            //     payDt = py.payDt; // Keep latest date
            //   }
            // } else {
            //   payDt = py.payDt;  // Initializes payDt
            // }
          });
          if (!primPayAmnt) {
            this.errors.push('Primary payment (Loop 2400)');
            primPayAmnt = this.emk;
          }
          sv += this.n + 'AMT*AAE*' + primPayAmnt + this.endOfLine; // APPROVED AMOUNT
        }

        if (!+p.noNTE || p.noDesc || p.reclReason) {  // When p.noDesc inhibits proc description in SV... segment add it in NTE segment unless there's a reclaim reason
          let nte: string = p.reclReason ? p.reclReason : p.desc; // Prioritize reclaimReason 
          if (!nte) {
            this.errors.push('NTE segment (Loop 2400)');
            nte = this.emk;
          }
          if (p.reclReason) {
            sv += this.n + 'NTE*ADD*' + p.reclReason + this.endOfLine
          } else {
            sv += this.n + 'NTE*ADD*' + p.desc + this.endOfLine
          }
        }

        const ndcs = this._recordService.localPcodes.filter(pc => { return +pc.rx && p.code === pc.code && cas.casProvID === pc.provId && insID === pc.insId });
        ndcs.some(n => {
          let ndc: string = p.rxNDC.replace(/\D*/g, '');
          if (+n.rx && p.code === n.code && cas.casProvID === n.provId && insID === n.insId && (ndc.length === 10 || ndc.length === 11)) {
            sv += this.n + 'LIN**N4*' + ndc + this.endOfLine
              + this.n + 'CTP****' + p.rxQty + '*' + p.rxUM + this.endOfLine;
            if (p.rxNo) {
              this.n + 'REF*XZ*' + p.rxNo + this.endOfLine;
            }
            return true;  // To break out of array.some loop
          }
          this.errors.push('National Drug Code (Loop 2410)');
          sv += this.n + 'LIN**N4*' + this.emk + this.endOfLine;
        });

        cnt++;
      }
    });
    if (!sv) {
      this.errors.push('No procedure codes found');
      sv = this.n + 'SV1*HC:' + this.emk + this.endOfLine;
    }
    return sv;
  }

  provNm(prv: ILocalProviders, qual: string) {
    if (prv.provIsCo) {
      return 'NM1*' + qual + '*2*' + prv.provName + '*****XX*' + prv.npi + this.endOfLine
    } else {
      return 'NM1*' + qual + '*1*' + prv.provName.match(/^\w+/g)[0] + '*' + prv.provName.match(/\w+$/g)[0] + '****XX*' + prv.npi + this.endOfLine
    }
  }

  isaGsEnvelope(sub: ILocalSubmitters, momDt: any, isa13: string, insNm: string, insPayID: string, typ: string, isaTestFlag: string): string {
    const yyyymody: string = moment(momDt).format('YYYYMMDD'); // ISA09 - Interchange Date
    const yrmody: string = moment(momDt).format('YYMMDD'); // ISA09 - Interchange Date
    const hrmi: string = moment(momDt).format('HHmm'); // ISA10 - Interchange Time
    const isa: string = `ISA*00*          *00*          *${sub.SubIsa05}*${(sub.SubIDrem + ' '.repeat(15)).substring(0, 15)}*${sub.SubIsa07}*${(sub.SubIsa08 + ' '.repeat(15)).substring(0, 15)}*${yrmody}*${hrmi}*^*00501*${isa13}*1*${isaTestFlag}*:~`
    let gs01: string;
    let gs08: string;
    let perCo: string = sub.SubFstNm ? '1' : '2';  // 1=person, 2=company
    const te: string = sub.SubContTel ? `*TE*${sub.SubContTel}` : '';
    const fx: string = sub.SubContFax ? `*FX*${sub.SubContFax}` : '';
    const em: string = sub.SubContEmail ? `*EM*${sub.SubContEmail}` : '';

    if (typ === '837P') {  // Professional claim
      gs01 = 'HC';
      gs08 = '005010X222A1';

      return isa
        + this.n + `GS*${gs01}*${sub.SubGs02}*${sub.SubGs03}*20${yrmody}*${hrmi}*1*X*${gs08}~`
        + this.n + `ST*837*${isa13}*${gs08}~`
        + this.n + `BHT*0019*00*${isa13}*${yyyymody}*${hrmi}*CH~`
        + this.n + `NM1*41*${perCo}*${sub.SubLastNm}*${sub.SubFstNm}****46*${sub.SubIDrem}~`
        + this.n + `PER*IC*${sub.SubContact}${te}${fx}${em}~`
        + this.n + `NM1*40*2*${insNm}*****46*${insPayID}~`
        + this.n + `HL*1**20*1~`
    }
    if (typ === '270') {  // Eligibility request
      gs01 = 'HS';
      gs08 = '005010X279A1';
      return isa
        + `GS*${gs01}*${sub.SubGs02}*${sub.SubGs03}*20${yrmody}*${hrmi}*1*X*${gs08}~`
    }
  }

  ckErrsClaim(clm: I837clm) {
    if (clm.sbr01.match(/SBR\*[PS]/g)) {
      this.errors.push('(P)rimary or (S)econdary) (Loop 2000B-sbr01), ' + clm.sbr01);
    }

    if (clm.sbr02.match(/SBR\*[PS]\*18/g)) {
      this.errors.push('Individual Relationship Code 18=Self (Loop 2000B-sbr02), ' + clm.sbr01);
    }

    if (clm.sbr05.match(/SBR\*[PS]\*\w\*\*12/g)) {
      this.errors.push('Insurance Type Code, 12 if Medicare Secondary Payer (Loop 2000B-sbr05), ' + clm.sbr05);
    }

    if (clm.sbr09) {
      this.errors.push('Claim Filing Indicator Code (Loop 2000B-sbr09), ' + clm.sbr09);
    }
  }

  save2q837(sn: string, s837: string, uID: string, casID: string, patID: string, ps: string, casDt: string): void {
    const sErrs = this.errors.map(s => s).reduce((acc, s) => acc + s + this.delm, '');
    const cDt = moment(casDt, 'MM/DD/YYYY').format('YYYY-MM-DD');

    console.log('%c' + 'query @ save2q837:' + "Exec spMB_Sio_Save2Q837 @sn = '" + sn + "', @s837 = '" + this._help.escApos(s837) + "', @sErrs = '" + this._help.escApos(sErrs) + "', @uID = '" + uID + "', @patID = '" + patID + "', @casID = '" + casID + "', @ps = '" + ps + "', @casDt = '" + cDt + "';", 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', sn, "Exec spMB_Sio_Save2Q837 @sn = '" + sn + "', @s837 = '" + this._help.escApos(s837) + "', @sErrs = '" + this._help.escApos(sErrs) + "', @uID = '" + uID + "', @patID = '" + patID + "', @casID = '" + casID + "', @ps = '" + ps + "', @casDt = '" + cDt + "';");
  }

  // Sample xml envelope from Fiddler
  // <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Header><h:AuthenticationHeader xmlns:h="https://www.inmediata.com/ws/EdiRealTime/" xmlns="https://www.inmediata.com/ws/EdiRealTime/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><Username>sobrinoc</Username><Password>Sc0190</Password></h:AuthenticationHeader></s:Header><s:Body xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><GetEligibility xmlns="https://www.inmediata.com/ws/EdiRealTime/"><X12Data>ISA*00*          *00*          *30*584384448      *30*660610220      *240923*2105*^*00501*000000026*1*P*:~GS*HS*660610220*660610220*20240923*2105*1*X*005010X279A1~ST*270*000000026*005010X279A1~BHT*0022*13*100000000*20240923*2105~HL*1**20*1~NM1*PR*2*MEDICARE*****PI*00973~HL*2*1*21*1~NM1*1P*1*SOBRINO CATONI*JOSE****XX*1174602064~HL*3*2*22*0~TRN*1*1000000000000017372*1174602064~NM1*IL*1*AYUSO BENITEZ*ROSALIA****MI*7U63G89ER78~DMG*D8*19380118~DTP*291*D8*20240923~EQ*30~SE*13*000000026~GE*1*1~IEA*1*000000026~</X12Data></GetEligibility></s:Body></s:Envelope>
  getInmeEligib(sn: string, usr: string, pw: string, x12Data: string): Observable<string> {
    this.sn = sn;
    return this._http.get<string>(this._help.urlDB + '/api/get-inmeEligib/' + sn + '/' + encodeURIComponent(usr) + '/' + encodeURIComponent(pw) + '/' + encodeURIComponent(x12Data))
      .pipe(
        catchError(err => this.handlrHttpError(err)));
  }

  sendInmeX837(sn: string, usr: string, pw: string, fil: string, x12Data: string): Observable<string> {
    this.sn = sn;
    const momDt = moment(new Date());
    const dt = momDt.format('MM/DD/YYYY HH:mm:ss');
    const data = x12Data.replace(/\n/g, '');
    return this._http.get<string>(this._help.urlDB + '/api/get-inmeSendX12File/' + sn + '/' + encodeURIComponent(usr) + '/' + encodeURIComponent(pw) + '/' + encodeURIComponent(fil) + '/' + encodeURIComponent(dt) + '/' + encodeURIComponent(data))
      .pipe(
        catchError(err => this.handlrHttpError(err)));
  }

  private handlrHttpError(error: HttpErrorResponse): Observable<any> {
    const errObj = {
      displayMsg: error.status.toString() + ' ' + error.statusText,
      msg: error.message
    }

    this.postApiErrorLog(this.sn, errObj, this.proc)
      .subscribe({
        next: (data: any) => console.log(data),
        error: (err: any) => console.error(err)
      });
    return throwError(() => errObj);
  }

  postApiErrorLog(SN: string, errObj: any, proc): Observable<any> {
    let errStr = JSON.stringify(errObj);
    let body = JSON.stringify({ SN, errStr, proc });

    return this._http.post<any>(this._help.urlDB + '/api/post-apiErrorLog', body, this.postHeaders);
  }

  delete837(sn: string, ps: string, casID: string) {
    this.sn = sn;
    const q = "Exec spMB_Sio_DeleteFromQ837 @sn = '" + this.sn + "', @casID = '" + casID + "', @ps = '" + ps + "';";
    console.log('%c' + 'q @ delete837():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  chkUnchk837(sn: string, pKey: string) {
    this.sn = sn;
    const q = "Exec spMB_Sio_Q837ChkUnchk @sn = '" + this.sn + "', @pKey = '" + pKey + "';";
    console.log('%c' + 'q @ chkUnchk837():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  saveSubmitter(sn: string, evnt: any) {
    this.sn = sn;
    const q = "Exec spMB_Sio_SaveSubmitter @sn = '" + this.sn
      + "', @subID = '" + evnt.SubID
      + "', @subLastNm = '" + this._help.escApos(evnt.SubLastNm)
      + "', @subFstNm = '" + this._help.escApos(evnt.SubFstNm)
      + "', @subIDrem = '" + this._help.escApos(evnt.SubIDrem)
      + "', @subContact = '" + this._help.escApos(evnt.SubContact)
      + "', @subContTel = '" + this._help.escApos(evnt.SubContTel)
      + "', @subContFax = '" + this._help.escApos(evnt.SubContFax)
      + "', @subContEmail = '" + this._help.escApos(evnt.SubContEmail)
      + "', @subIsa05 = '" + this._help.escApos(evnt.SubIsa05)
      + "', @subIsa07 = '" + this._help.escApos(evnt.SubIsa07)
      + "', @subIsa08 = '" + this._help.escApos(evnt.SubIsa08)
      + "', @subGs02 = '" + this._help.escApos(evnt.SubGs02)
      + "', @subGs03 = '" + this._help.escApos(evnt.SubGs03)
      + "', @subVia = '" + this._help.escApos(evnt.SubVia)
      + "', @subUser = '" + this._help.escApos(evnt.SubUser)
      + "', @subPw = '" + this._help.escApos(evnt.SubPw)
      + "', @provID = '" + evnt.provID
      + "', @insID = '" + evnt.insID
      + "';"
    console.log('%c' + 'q @ saveSubmitter():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

  deleteSubmitter(sn: string, evnt: any) {
    this.sn = sn;
    const q = "Exec spMB_Sio_DeleteSubmitter @sn = '" + this.sn
      + "', @subID = '" + evnt.SubID
      + "';"
    console.log('%c' + 'q @ deleteSubmitter():' + q, 'color: black; background: #90EE90; font-size: 12px');
    this._ws.sendChat('query', this.sn, q);
  }

}
