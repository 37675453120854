import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpContextToken } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators'

import { HttpCacheService } from '../shared/http-cache.service';
export const CACHEABLE = new HttpContextToken(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(private cacheService: HttpCacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('CacheInterceptor - log req/res');
        console.log(`${req.method} ${req.url}`);
        console.log('req.body = ' + req.body);
        console.log('req.params = ' + req.params);
        console.log('^^^^^');

        if (req.url.includes('api/get-inmeSendX12File')) {
            console.log('CacheInterceptor - api/get-inmeSendX12File INVALIDATED');
            this.cacheService.invalidateCache();
            return next.handle(req);
        }

        if (!req.context.get(CACHEABLE)) {
            return next.handle(req);
        }

        if (req.method !== 'GET') { // Pass along non-cacheable methods and invalidate the cache
            console.log(`Invalidating the cache: ${req.method} ${req.url}`);
            this.cacheService.invalidateCache();
            return next.handle(req);
        }

        const cachedResponse: HttpResponse<any> = this.cacheService.get(req.url);   // Attempt to retrieve cached response
        if (cachedResponse) {
            console.log(`Returning a cached response: ${cachedResponse.url}`)
            console.log(cachedResponse);
            return of(cachedResponse);
        }

        return next.handle(req) // In case response was not retrieved from cache
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        console.log(`Adding item to cache: ${req.url}`);
                        this.cacheService.put(req.url, event);
                    }
                })
            );
    }
}